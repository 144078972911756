<template>
  <section :class="$style.container">
    <Wrapper id="home-cards" :class="$style.inner">
      <PImage
        v-for="(item, index) in items"
        :key="index"
        :image-object="item.press_logo"
        :class="$style.image"
      />
    </Wrapper>
  </section>
</template>

<script>
import PImage from '~/components/PImage'

export default {
  name: 'HomePressList',

  components: {
    PImage,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" module>
.container {
  display: none;

  background-color: $bluko-500;

  @include above(small) {
    display: block;
  }
}

.inner {
  display: flex;
  flex-wrap: wrap;
  gap: 32px 75px;
  justify-content: center;
  margin: 0 auto;
  padding: 20px 24px;
}

.image {
  height: 48px;
  object-fit: contain;
}
</style>
