<template>
  <section :class="$style.questionsContainer" data-luko-tracking="ArticlesList">
    <Wrapper :class="$style.inner">
      <div :class="$style.titleContainer">
        <Title :class="$style.title" :title-params="content.title" />
        <div :class="$style.desktopCta">
          <LkButton is-secondary :href="content.cta_link.url">
            {{ content.cta_text }}
          </LkButton>
        </div>
      </div>
      <div :class="$style.list">
        <ArticleThumbnail v-for="(article, index) in articles" :key="index" :class="$style.article" :article="article" is-horizontal />
      </div>
      <div :class="$style.mobileCta">
        <LkButton is-secondary :href="content.cta_link.url">
          {{ content.cta_text }}
        </LkButton>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import Title from '~/components/Title'
import ArticleThumbnail from '~/components/ArticleThumbnail'

export default {
  name: 'ArticlesList',

  components: {
    Title,
    ArticleThumbnail,
  },

  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  async fetch() {
    const ids = this.slice.items.map((item) => item.article.id)

    const articles = await this.$prismic.api.getByIDs(ids)
    this.articles = articles.results
  },

  data() {
    return {
      articles: [],
    }
  },

  computed: {
    content() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.questionsContainer {
  position: relative;

  margin: 128px 24px;
}

.title {
  @include typo-large-title;
}

.list {
  display: grid;
  gap: 40px;
  margin-top: 56px;

  @include above(small) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.article {
  max-width: none;
  @include below(large) {
    & > div {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .desktopCta {
    display: none;
    @include above(small) {
      display: block;
    }
  }
}
.mobileCta {
  margin: 24px auto;

  text-align: center;
  @include above(small) {
    display: none;
  }
}
</style>
