<template>
  <section :class="$style.questionsContainer" data-luko-tracking="FaqSection">
    <Wrapper :class="$style.inner">
      <Title :class="$style.title" :title-params="primary.title" />
      <ul :class="$style.list">
        <li
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          :class="$style.item"
        >
          <Title
            :class="$style.itemTitle"
            :title-params="item.question_title"
          />
          <RichText
            :class="$style.itemContent"
            :text-params="item.question_text"
          />
          <PLink
            v-if="item.link_text && item.link_url.url"
            is-highlight
            color="bluko"
            :link-object="item.link_url"
            :class="$style.link"
          >
            {{ item.link_text }}
          </PLink>
        </li>
      </ul>
      <LkButton
        v-if="primary.cta_text"
        :class="$style.button"
        :href="getFormatedLink(primary.cta_link)"
      >
        {{ primary.cta_text }}
      </LkButton>
    </Wrapper>
  </section>
</template>

<script>
import { getFormatedLink } from '~/mixins/getFormatedLink'
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import PLink from '~/components/PLink'

export default {
  name: 'FaqSection',

  jsonld() {
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: this.items.map(({ question_title, question_text }) => ({
        '@type': 'Question',
        name: question_title[0].text,
        acceptedAnswer: {
          '@type': 'Answer',
          text: question_text[0].text,
        },
      })),
    }
  },

  components: {
    Title,
    RichText,
    PLink,
  },

  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items.filter(
        (item) =>
          (item.question_title && item.question_title.length) ||
          (item.question_text && item.question_text.length)
      ),
    }
  },
}
</script>

<style lang="scss" module>
.questionsContainer {
  position: relative;

  background-color: #fcf7e8;
}

.inner {
  display: flex;
  flex-direction: column;
  padding: 40px 24px;

  @include above(medium) {
    padding: 80px 24px;
  }
}

.title {
  @include typo-large-title;

  max-width: 700px;
  margin: 0 auto;

  text-align: center;
}

.list {
  margin-top: 40px;

  @include above(medium) {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 56px;
  }
}

.item {
  &:not(:first-child) {
    margin-top: 48px;
  }

  @include above(medium) {
    flex-basis: calc(50% - 40px);

    &:not(:first-child) {
      margin-top: 0;
    }
  }
}

.itemTitle {
  @include typo-headline;
  font-weight: 500;
}

.itemContent {
  margin-top: 16px;

  color: $gray-700;
}

.link {
  margin-top: 8px;
}

.button {
  margin: 80px auto 0;
}
</style>
