// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hero__container___2mriE{max-width:inherit;margin-top:-64px;padding:0 24px;background-color:#fcf7e8;background-repeat:repeat-x;background-position:0 100%;background-size:2048px 594px}@media (max-width:959px){.Hero__container___2mriE{background-position-x:-270px;background-size:1024px 287px}}@media (min-width:2000px){.Hero__container___2mriE{background-position-x:500px;background-position-y:bottom}}@media (min-width:3000px){.Hero__container___2mriE{background-position-x:1000px;background-position-y:bottom}}.Hero__inner___1h-eq{display:flex;min-height:630px;padding-top:96px}@media (min-width:960px){.Hero__inner___1h-eq{min-height:680px;padding-top:144px}}.Hero__desc___BPwEq{max-width:720px;margin:0 auto}@media (min-width:960px){.Hero__desc___BPwEq{margin:0}}.Hero__title___3Igqs h1{max-width:725px;margin-bottom:16px;color:#2c2302;font-weight:500;font-size:32px;line-height:48px}@media (min-width:960px){.Hero__title___3Igqs h1{font-size:52px;line-height:64px}}.Hero__subtitle___1zeyZ{max-width:650px;margin:0;color:#564f34;font-size:20px;line-height:32px}@media (max-width:959px){.Hero__subtitle___1zeyZ{font-size:16px;line-height:24px}}.Hero__ctaBox___29vMY{margin-top:56px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "Hero__container___2mriE",
	"inner": "Hero__inner___1h-eq",
	"desc": "Hero__desc___BPwEq",
	"title": "Hero__title___3Igqs",
	"subtitle": "Hero__subtitle___1zeyZ",
	"ctaBox": "Hero__ctaBox___29vMY"
};
module.exports = ___CSS_LOADER_EXPORT___;
