<template>
  <section :class="$style.container" data-luko-tracking="CardSlice">
    <Wrapper :class="$style.inner">
      <div
        v-for="(card, cardIndex) in items"
        :id="blockId(card.title[0].text)"
        :key="cardIndex"
        :class="$style.card"
      >
        <div :class="$style.imageContainer">
          <PImage :image-object="card.icon" :class="$style.icon" />
        </div>
        <Title :title-params="card.title" :class="$style.title" is-title />
        <RichText :text-params="card.text" :class="$style.text" />
        <PLink
          :link-object="card.link_url"
          :class="$style.link"
          is-highlight
          color="bluko"
        >
          {{ card.link_text }}
        </PLink>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import PImage from '~/components/PImage'
import PLink from '~/components/PLink'

export default {
  name: 'CardSlice',

  components: {
    Title,
    RichText,
    PImage,
    PLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },
  },

  methods: {
    blockId(title) {
      const formatedTitle = title
        .split(' ')
        .map((e) => e.split(''))
        .filter((e) => e.length > 1)
        .map((e, i, a) => e.join(''))
        .join('-')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
      return formatedTitle
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 128px 0 0;
  padding: 0 24px;
}

.inner {
  display: grid;

  grid-gap: 40px;

  @include above(medium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  padding: 32px 24px;
  border-radius: 8px;

  background-color: #fcf7e8;

  @include above(small) {
    padding: 80px 73px;
  }
}

.imageContainer {
  text-align: center;
}

.icon {
  width: 56px;
  height: 56px;
}

.title {
  margin-top: 16px;

  text-align: center;
}

.text {
  margin-top: 16px;

  text-align: center;
}

.link {
  display: block;
  margin: 0 auto;
  margin-top: 24px;

  text-align: left;
}
</style>
