// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PressList__container___2cPtz{display:none;background-color:#2d50e6}@media (min-width:720px){.PressList__container___2cPtz{display:block}}.PressList__inner___Lr9pa{display:flex;flex-wrap:wrap;grid-gap:32px 75px;gap:32px 75px;justify-content:center;margin:0 auto;padding:20px 24px}.PressList__image___24vYs{height:48px;-o-object-fit:contain;object-fit:contain}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "PressList__container___2cPtz",
	"inner": "PressList__inner___Lr9pa",
	"image": "PressList__image___24vYs"
};
module.exports = ___CSS_LOADER_EXPORT___;
