// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsletterSection__container___4c_kY{padding:0 24px;background-color:#ebf5f3;background-position:bottom;background-size:cover}.NewsletterSection__inner___16dt4{max-width:620px;margin:0 auto;padding-bottom:80px;text-align:center}.NewsletterSection__hasBackground___2LVbu{padding-bottom:500px}.NewsletterSection__title___7u4gV{text-align:center}.NewsletterSection__newsletterInput___3shrM{margin-top:24px}.NewsletterSection__ctaBox___2SgMH{margin:56px auto 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "NewsletterSection__container___4c_kY",
	"inner": "NewsletterSection__inner___16dt4",
	"hasBackground": "NewsletterSection__hasBackground___2LVbu",
	"title": "NewsletterSection__title___7u4gV",
	"newsletterInput": "NewsletterSection__newsletterInput___3shrM",
	"ctaBox": "NewsletterSection__ctaBox___2SgMH"
};
module.exports = ___CSS_LOADER_EXPORT___;
