// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SensorListSlice__container___3l1s5{display:flex;justify-content:space-between;max-width:840px;margin-top:40px}@media (max-width:719px){.SensorListSlice__container___3l1s5{flex-direction:column;align-items:center}}.SensorListSlice__item___3iu3T{display:flex;flex-direction:column;max-width:254px}@media (max-width:719px){.SensorListSlice__item___3iu3T{justify-content:center;text-align:center}.SensorListSlice__item___3iu3T img{margin:0 auto}}.SensorListSlice__item___3iu3T img{width:40px;height:40px}.SensorListSlice__richtext___1qSQe h1{margin:12px 0 1px;color:#000;font-weight:500;font-size:20px;line-height:32px}.SensorListSlice__richtext___1qSQe p{margin:0 0 56px;color:#6b6651;font-weight:400;font-size:16px;line-height:24px}.SensorListSlice__richtext___1qSQe a{color:#2c2302}.SensorListSlice__richtext___1qSQe ul{display:block;margin-left:40px;list-style:outside;list-style-type:disc}.SensorListSlice__richtext___1qSQe ul li{display:list-item;margin:8px 0;color:#2c2302;font-size:16px;line-height:24px;text-align:-webkit-match-parent}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "SensorListSlice__container___3l1s5",
	"item": "SensorListSlice__item___3iu3T",
	"richtext": "SensorListSlice__richtext___1qSQe"
};
module.exports = ___CSS_LOADER_EXPORT___;
