<template>
  <section
    :id="content.section_id ? content.section_id : false"
    data-luko-tracking="HighlightBlockSlice"
    :class="[$style.container, { [$style.singleElement]: isSingleElement }]"
  >
    <Wrapper :class="$style.inner">
      <article
        v-for="(item, index) in items"
        :key="index"
        :class="[
          { [$style.single]: items.length === 1 },
          { [$style.bigText]: item.big_text },
        ]"
      >
        <PImage
          v-if="item.icon.url"
          :image-object="item.icon"
          sizes="xs:60px"
          :quality="50"
        />
        <Title :title-params="item.title" :class="$style.title" />
        <RichText :text-params="item.text" :class="$style.description" />
        <PLink
          v-if="item.cta_title && item.cta_link.url"
          :link-object="item.cta_link"
          is-highlight
          color="bluko"
        >
          {{ item.cta_title }}
        </PLink>
      </article>
    </Wrapper>
  </section>
</template>

<script>
import RichText from '~/components/RichText'
import PLink from '~/components/PLink'
import PImage from '~/components/PImage'

export default {
  name: 'HighlightBlockSlice',

  components: {
    RichText,
    PLink,
    PImage,
  },

  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    content() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },

    isSingleElement() {
      return this.slice.items.length === 2
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 24px;

  @include above(small) {
    margin: 80px 24px;
  }
}

.title:first-child p {
  @include typo-title;
  margin-top: 16px;

  color: $gray-1000;
  line-height: 40px;
}
.title p {
  @include typo-headline-bold;
  margin-top: 16px;

  color: $gray-1000;
}

.description {
  @include typo-body;
  margin-top: 8px;

  color: $gray-700;
}

.inner {
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  justify-items: center;

  padding: 40px 24px;
  border-radius: 8px;

  background-color: #fcf7e8;

  @include above(small) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    padding: 56px 72px;
  }

  @include above(medium) {
    grid-template-columns: repeat(4, 1fr);
  }

  article {
    width: 100%;
    margin-bottom: 40px;

    text-align: center;

    @include above(small) {
      max-width: 254px;
      margin-bottom: 0;

      text-align: left;
    }

    @include above(medium) {
    }

    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: 100%;
      max-width: 200px;
      height: 100%;
      max-height: 48px;
      object-fit: contain;
      object-position: center;

      @include above(small) {
        object-position: left;
      }
    }

    a {
      margin-top: 8px;

      color: $bluko-500;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
    }

    &.bigText {
      .title {
        @include typo-extra-title;
      }

      p {
        margin-top: 0;

        @include typo-headline;
        color: $gray-700;
      }
    }
  }
}

@include above(small) {
  .single {
    display: flex;

    .content {
      display: flex;
      gap: 40px;

      * {
        margin-top: 0;
      }

      .title {
        flex-basis: 100%;
        max-width: 254px;
      }
    }
  }
}

.singleElement {
  .inner {
    article {
      title {
        margin: 0;
      }
      &:last-child {
        max-width: 100%;
        @include above(small) {
          grid-column: span 1;
        }

        @include above(medium) {
          grid-column: span 3;
        }
      }
    }
  }
}
</style>
