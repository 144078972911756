<template>
  <section id="article" :class="$style.articleContainer" data-luko-tracking="ArticleSlice">
    <div :class="$style.inner">
      <aside :class="$style.sideBar">
        <Author v-if="content.authorInfos" :author-infos="content.authorInfos" :last-publication="content.lastPublication" />
        <Summary :titles-array="titleList" :titles-progression="titleProgression" :class="$style.summary" :sticky-cta="stickyCta" />
      </aside>
      <div :class="$style.content">
        <template v-for="(item, index) in items">
          <component
            :is="componentName(item.slice_type)"
            v-if="componentName(item.slice_type)"
            :key="index"
            :slice="item"
            @title-active="addTitleToProgression($event)"
            @title-inactive="removeTitleFromProgression($event)"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { NewsLetter, LukofferCta } from '@@/shared-slices'

import {
  SecondTitle,
  highlight_section,
  Introduction,
  TextBlock,
  Summary,
  ArticleLink,
  Author,
  ArticleImage,
  ArticleDocument,
  TweetsList,
  ArticleButton,
  ArticleTable,
  BannerCta,
  CardCta,
} from './components'

export default {
  name: 'ArticleSlice',

  components: {
    SecondTitle,
    highlight_section,
    Introduction,
    TextBlock,
    Summary,
    ArticleLink,
    Author,
    ArticleImage,
    ArticleDocument,
    TweetsList,
    ArticleButton,
    ArticleTable,
    BannerCta,
    CardCta,
    NewsLetter,
    LukofferCta,
  },

  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      titleProgression: [],
    }
  },

  computed: {
    content() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },

    titleList() {
      return this.items.filter((e) => ['second_title', 'article_second_title'].includes(e.slice_type)).map((e) => e.primary.text[0].text)
    },
    stickyCta() {
      return this.slice.items.filter((s) => s.slice_type === 'sticky_cta')
    },
  },

  methods: {
    componentName(value) {
      switch (value) {
        case 'text':
        case 'article_text':
          return 'TextBlock'

        case 'image':
        case 'article_image':
          return 'ArticleImage'

        case 'second_title':
        case 'article_second_title':
          return 'SecondTitle'

        case 'link':
          return 'ArticleLink'

        case 'document':
          return 'ArticleDocument'

        case 'article_twitter':
          return 'TweetsList'

        case 'article_button':
          return 'ArticleButton'

        case 'article_table':
          return 'ArticleTable'

        case 'introduction':
        case 'article_introduction':
          return 'Introduction'

        case 'article_highlight_section':
        case 'highlight_section':
          return 'highlight_section'

        case 'banner_cta':
          return 'BannerCta'

        case 'card_cta':
          return 'CardCta'

        case 'news_letter':
          return 'NewsLetter'

        case 'lukoffer_cta':
          return 'LukofferCta'

        default:
          return undefined
      }
    },

    addTitleToProgression(title) {
      if (!this.titleProgression.includes(title)) {
        this.titleProgression.push(title)
      }
    },

    removeTitleFromProgression(title) {
      const index = this.titleProgression.indexOf(title)
      if (index > -1) {
        this.titleProgression.splice(index, 1)
      }
    },
  },
}
</script>

<style lang="scss" module>
.sideBar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-self: stretch;
  max-width: 300px;

  @include above(large) {
    max-width: 327px;
  }
}

.summary {
  display: none;

  @include above(medium) {
    display: block;
    flex-grow: 1;
  }
}

.articleContainer {
  margin: 80px 24px 128px;

  @include below(medium) {
    margin: 80px 24px 64px;
  }
}

.inner {
  max-width: 620px;
  margin: 0 auto;

  @include above(medium) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1280px;
  }
}

.content {
  max-width: 620px;
  overflow-x: hidden;

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  & > div:first-child {
    padding-top: 0;
  }

  @include above(medium) {
    margin: 0 80px;
  }
}
</style>
