<template>
  <section
    :class="$style.container"
    :style="sectionStyle"
    style="position: relative"
    data-luko-tracking="BottomBanner"
  >
    <PImage
      v-if="primary.left_image"
      loading="lazy"
      :image-object="primary.left_image"
      :class="$style.left_image"
      size="xs:500px"
    />
    <div :class="$style.content">
      <Title :title-params="primary.title" :class="$style.title" is-title />
      <LkButton
        v-if="!primary.has_onboarding_form"
        :class="$style.button"
        :href="getFormatedLink(primary.cta_url)"
        data-cta="calendly"
      >
        {{ primary.cta_title }}
      </LkButton>
      <AddressForm
        v-else
        :class="$style.addressForm"
        :placeholder="primary.form_placeholder"
        :button-text="primary.form_cta_text"
      />
    </div>
    <PImage
      v-if="primary.right_image"
      :class="$style.right_image"
      loading="lazy"
      :image-object="primary.right_image"
      size="xs:500px"
    />
  </section>
</template>

<script>
import { getFormatedLink } from '~/mixins/getFormatedLink'
import Title from '~/components/Title'
import PImage from '~/components/PImage'
import AddressForm from '~/components/AddressForm'

export default {
  name: 'BottomBanner',

  components: {
    Title,
    PImage,
    AddressForm,
  },

  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },

  computed: {
    sectionStyle() {
      const backgroundImage = this.primary?.illustration?.url
        ? `background-image:url(${this.primary?.illustration?.url})`
        : ''

      return backgroundImage
    },
  },
}
</script>

<style lang="scss" module>
.container {
  position: relative;

  padding: 0 24px;

  overflow: hidden;

  background-color: #fcf7e8; // should be $cream-500 but illu isn't transparent
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;

  @include above(medium) {
    min-height: 330px;
    padding: 0;
    // overflow: visible;
  }

  @include below(medium) {
    .right_image {
      top: auto;
      bottom: -80px;

      display: block;
      height: 315px;
    }
  }
}

.content {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 32px 0 260px;

  @include above(small) {
    padding: 76px 0;
  }
}

.title {
  max-width: 702px;

  text-align: center;

  @include below(medium) {
    max-width: 502px;

    font-size: 20px;
    line-height: 32px;
  }
}

.button {
  width: 100%;
  margin-top: 32px;

  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    text-decoration: none;
  }

  @include above(small) {
    width: auto;
  }

  @include above(medium) {
    margin-top: 40px;
  }
}

.addressForm {
  max-width: 546px;
  margin: 0 auto;
  margin-top: 32px;

  @include above(small) {
    margin-top: 40px;
  }
}

.left_image,
.right_image {
  position: absolute;
  top: 0;
}

.left_image {
  left: 0;

  display: none;
  width: 480px;
  height: 400px;

  @include above(medium) {
    display: block;
  }
}

.right_image {
  top: auto;
  right: 0;
  bottom: -80px;

  height: 315px;

  @include above(medium) {
    top: 0;
    bottom: inherit;

    width: 480px;
    height: auto;
  }
}
</style>
