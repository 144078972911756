<template>
  <div data-luko-tracking="HomePage">
    <component
      :is="slice.slice_type"
      v-for="(slice, sliceIndex) in slices"
      :key="sliceIndex"
      :items="slice.items"
      :primary="slice.primary"
      :slice="slice"
    />
  </div>
</template>

<script>
import {
  Hero,
  LogoList,
  MediaCarousel,
  TrustpilotCarousel,
  HighlightCounter,
  TrustpilotComparison,
  CustomerService,
  PressListCarousel,
  CertificationSection,
  GivebackSchema,
} from '@@/shared-slices'

import { BundleProduct } from './components'

export default {
  name: 'HomeModule',
  components: {
    hero: Hero,
    logo_list: LogoList,
    media_carousel: MediaCarousel,
    trustpilot_carousel: TrustpilotCarousel,
    highlight_counter: HighlightCounter,
    trustpilot_comparison: TrustpilotComparison,
    customer_service: CustomerService,
    press_list_carousel: PressListCarousel,
    giveback_schema: GivebackSchema,
    certification_section: CertificationSection,
    bundle_product: BundleProduct,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    slices() {
      return this.data.body
    },
  },
}
</script>
