<template>
  <section :class="$style.container" data-luko-tracking="QuinconceSlice">
    <Title v-if="content.title" :title-params="content.title" :class="$style.title" />
    <Wrapper :class="$style.inner">
      <div
        v-for="(item, itemIndex) in items"
        :id="blockId(item.title[0].text)"
        :key="itemIndex"
        :class="[$style.content, (content.is_reverse || isReverse) && $style.reverseContent]"
        data-aos="fade-up"
      >
        <div :class="$style.view">
          <PImage loading="lazy" :image-object="item.image" sizes="sm:90vw" :quality="80" />
        </div>
        <div :class="$style.desc">
          <PImage v-if="item.icon && item.icon.url" :class="$style.icon" :image-object="item.icon" />
          <div :class="$style.titleContainer">
            <Title :class="$style.contentTitle" is-large-title :title-params="item.title" />
            <span v-if="item.tag" :class="$style.tag">
              {{ item.tag }}
            </span>
          </div>
          <RichText :text-params="item.text" :class="$style.contentDescription" />
          <div v-if="item.trustpilot" :class="$style.trustpilot">
            <RichText :text-params="item.trustpilot_text" :class="$style.trustpilotText" />
            <img src="~/assets/icons/socials/trustpilot-stars.svg" alt="trustpilot icon" :class="$style.trustpilotStars" />
            <img src="~/assets/icons/socials/trustpilot-star.svg" alt="trustpilot icon" />
          </div>

          <CtaBox :item="item" />

          <div v-if="item.has_app_link" :class="$style.app_links">
            <PLink :link-object="content.cta_app_store">
              <PImage :image-object="content.image_app_store" :class="$style.app_badge" sizes="xs:200px" />
            </PLink>
            <PLink :link-object="content.cta_google_play">
              <PImage :image-object="content.image_google_play" :class="$style.app_badge" sizes="xs:200px" />
            </PLink>
          </div>
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import PImage from '~/components/PImage'
import PLink from '~/components/PLink'

import CtaBox from './components/CtaBox.vue'

export default {
  name: 'QuinconceSlice',
  components: {
    PImage,
    PLink,
    CtaBox,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
    isReverse: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    content() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },
  },

  methods: {
    blockId(title) {
      const formatedTitle = title
        .split(' ')
        .map((e) => e.split(''))
        .filter((e) => e.length > 1)
        .map((e, i, a) => e.join(''))
        .join('-')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
      return formatedTitle
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 128px 0;
  padding: 0 24px;
}

.inner {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  row-gap: 128px;
  @include below(small) {
    row-gap: 80px;
  }
}

.title {
  max-width: 840px;
  margin: 128px auto 80px;

  text-align: center;

  @include typo-extra-title;
}

.content {
  &:first-child {
    margin-top: 0;
  }

  @include above(small) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    column-gap: 24px;

    &:nth-child(2n + 1) {
      flex-direction: row-reverse;
    }

    @include above(small) {
      column-gap: 32px;
    }
    @include above(large) {
      column-gap: 40px;
    }
  }
}

.reverseContent {
  @include above(small) {
    &:nth-child(2n + 1) {
      flex-direction: row !important;
    }
    &:nth-child(2n) {
      flex-direction: row-reverse;
    }
  }
}
.desc {
  flex-basis: 0;
  flex-grow: 1;
  margin-top: 32px;

  a {
    margin-top: 40px;
  }

  @include above(small) {
    margin-top: 0;
  }
  @include above(medium) {
    padding: 0 73px;
  }
}

.icon {
  display: none;

  @include above(small) {
    display: block;
    width: 56px;
    height: 56px;
    margin-bottom: 16px;
  }
}

.contentTitle h2,
.contentTitle {
  display: inline;

  font-weight: 200;
}

.tag {
  display: inline-block;
  margin-top: 8px;
  padding: 6px 6px 3px;
  border-radius: 4px;

  color: white;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;

  background-color: $gold-500;

  transform: translateY(-4px);
}

.contentDescription {
  margin-top: 16px;

  color: $gray-700;

  ul > li {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.trustpilot {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.trustpilotStars {
  margin: 0 10px;
}
.trustpilotText {
  color: black;
  @include below(small) {
    font-size: 12px;
  }
}

.view {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 620px;

  img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }

  @include above(medium) {
    flex-basis: 15%;
  }
}

.app_links {
  display: flex;
  justify-content: left;

  column-gap: 25px;

  @include below(small) {
    justify-content: center;

    column-gap: 17px;
  }
}

.app_badge {
  height: 48px;

  @media screen and (max-width: 374px) {
    width: 40vw;
    height: auto;
  }
}
</style>
