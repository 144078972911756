<template>
  <section :class="$style.container">
    <div :class="$style.inner">
      <PImage v-if="content.image" :image-object="content.image" />
      <figure :class="$style.figure">
        <Title :title-params="content.title" :class="$style.title" />
        <RichText
          v-if="content.description"
          :class="$style.subtitle"
          :text-params="content.description"
        />
      </figure>
    </div>
  </section>
</template>

<script>
import RichText from '~/components/RichText'
import Title from '~/components/Title'

export default {
  name: 'SubHeroBanner',

  components: {
    RichText,
    Title,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.container {
  background: $bluko-500;
}

.inner {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 60px 24px;

  img {
    width: 118px;
    height: 118px;
    margin-right: 40px;
  }

  @include below(medium) {
    flex-direction: column;

    text-align: center;
    img {
      width: 64px;
      height: 64px;
      margin: 0;
      margin-bottom: 32px;
    }
  }
}

.figure {
  max-width: 530px;
}

.title {
  color: #ffffff;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  @include below(medium) {
    font-size: 20px;
    line-height: 32px;
  }
}

.subtitle p {
  color: #ffffff;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  @include below(medium) {
    color: $bluko-200;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
