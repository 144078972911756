// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SimpleSection__container___23lAa{padding:0 24px}.SimpleSection__inner___15F_M{margin-top:128px;margin-bottom:128px}.SimpleSection__content___17wQW{margin-top:32px}@media (min-width:720px){.SimpleSection__content___17wQW{display:flex;align-items:center;justify-content:space-between;grid-column-gap:24px;-moz-column-gap:24px;column-gap:24px}.SimpleSection__content___17wQW.SimpleSection__isReverse___1SYz4{flex-direction:row-reverse}}@media (min-width:720px){.SimpleSection__content___17wQW{grid-column-gap:32px;-moz-column-gap:32px;column-gap:32px}}@media (min-width:1280px){.SimpleSection__content___17wQW{grid-column-gap:40px;-moz-column-gap:40px;column-gap:40px}}.SimpleSection__desc___36F7R{flex-basis:0;flex-grow:1}.SimpleSection__desc___36F7R a{margin-top:40px}@media (min-width:720px){.SimpleSection__desc___36F7R{margin-top:0}}@media (min-width:960px){.SimpleSection__desc___36F7R{padding:0 73px}}.SimpleSection__contentTitle___1iLvy{text-align:center}.SimpleSection__contentSubtitle___2iL0d{color:inherit;font-weight:500;font-size:32px;line-height:48px}.SimpleSection__contentSubtitle___2iL0d p{margin-bottom:10px;color:#2c2302;line-height:48px}.SimpleSection__contentDescription___15zLj{margin-top:16px;color:#6b6651}.SimpleSection__view___1YePu{flex-basis:0;flex-grow:1;max-width:620px}.SimpleSection__view___1YePu img{display:block;width:100%}@media (min-width:960px){.SimpleSection__view___1YePu{flex-basis:15%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "SimpleSection__container___23lAa",
	"inner": "SimpleSection__inner___15F_M",
	"content": "SimpleSection__content___17wQW",
	"isReverse": "SimpleSection__isReverse___1SYz4",
	"desc": "SimpleSection__desc___36F7R",
	"contentTitle": "SimpleSection__contentTitle___1iLvy",
	"contentSubtitle": "SimpleSection__contentSubtitle___2iL0d",
	"contentDescription": "SimpleSection__contentDescription___15zLj",
	"view": "SimpleSection__view___1YePu"
};
module.exports = ___CSS_LOADER_EXPORT___;
