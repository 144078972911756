// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HorizontalListSlice__container___iqTBy{margin:128px 0;padding:0 24px}.HorizontalListSlice__inner___2sJ5r{width:100%;max-width:1280px;margin:0 auto}.HorizontalListSlice__title___3BwAw{text-align:center}.HorizontalListSlice__listContainer___3IOBv{display:flex;flex-direction:column;grid-gap:40px;gap:40px;margin-top:80px}@media (min-width:720px){.HorizontalListSlice__listContainer___3IOBv{flex-direction:row;flex-wrap:wrap;justify-content:center}}.HorizontalListSlice__item___2qXNP{flex-grow:1;width:100%;max-width:400px;margin:0 auto;text-align:center}@media (min-width:720px){.HorizontalListSlice__item___2qXNP{width:45%}}@media (min-width:960px){.HorizontalListSlice__item___2qXNP{width:30%;width:auto}}.HorizontalListSlice__icon___34dQd{width:48px;height:48px;margin:0 auto;-o-object-fit:cover;object-fit:cover}.HorizontalListSlice__text___3L0ju{margin-top:24px}.HorizontalListSlice__text___3L0ju h3,.HorizontalListSlice__text___3L0ju h4{color:inherit;font-weight:400;font-size:20px;line-height:32px;font-weight:500}.HorizontalListSlice__text___3L0ju p{margin-top:8px}.HorizontalListSlice__link___1fWK2{margin-top:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "HorizontalListSlice__container___iqTBy",
	"inner": "HorizontalListSlice__inner___2sJ5r",
	"title": "HorizontalListSlice__title___3BwAw",
	"listContainer": "HorizontalListSlice__listContainer___3IOBv",
	"item": "HorizontalListSlice__item___2qXNP",
	"icon": "HorizontalListSlice__icon___34dQd",
	"text": "HorizontalListSlice__text___3L0ju",
	"link": "HorizontalListSlice__link___1fWK2"
};
module.exports = ___CSS_LOADER_EXPORT___;
