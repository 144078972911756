// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubHeroBanner__container___3FIby{background:#2d50e6}.SubHeroBanner__inner___1QBRT{display:flex;flex:1;align-items:center;justify-content:center;width:100%;padding:60px 24px}.SubHeroBanner__inner___1QBRT img{width:118px;height:118px;margin-right:40px}@media (max-width:959px){.SubHeroBanner__inner___1QBRT{flex-direction:column;text-align:center}.SubHeroBanner__inner___1QBRT img{width:64px;height:64px;margin:0 0 32px}}.SubHeroBanner__figure___324Ih{max-width:530px}.SubHeroBanner__title___1utL9{color:#fff;font-weight:500;font-size:24px;line-height:32px}@media (max-width:959px){.SubHeroBanner__title___1utL9{font-size:20px;line-height:32px}}.SubHeroBanner__subtitle____hN5X p{color:#fff;font-weight:400;font-size:16px;line-height:24px}@media (max-width:959px){.SubHeroBanner__subtitle____hN5X p{color:#acbef7;font-size:16px;line-height:24px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "SubHeroBanner__container___3FIby",
	"inner": "SubHeroBanner__inner___1QBRT",
	"figure": "SubHeroBanner__figure___324Ih",
	"title": "SubHeroBanner__title___1utL9",
	"subtitle": "SubHeroBanner__subtitle____hN5X"
};
module.exports = ___CSS_LOADER_EXPORT___;
