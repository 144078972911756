// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBox__isDark___3ilT7 .CtaBox__emailField___1XZ32 input{border-color:#2441c5;color:#fff;background-color:#1b31a4}.CtaBox__isDark___3ilT7 .CtaBox__emailField___1XZ32 input::-moz-placeholder{color:#fff}.CtaBox__isDark___3ilT7 .CtaBox__emailField___1XZ32 input:-ms-input-placeholder{color:#fff}.CtaBox__isDark___3ilT7 .CtaBox__emailField___1XZ32 input::placeholder{color:#fff}.CtaBox__isDark___3ilT7 .CtaBox__emailField___1XZ32 input:hover{border-color:#829af2}.CtaBox__isDark___3ilT7 .CtaBox__emailField___1XZ32 input:focus{border-color:#829af2;box-shadow:0 0 0 4px #2d50e6}@media (max-width:719px){.CtaBox__button___kAaMB{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"isDark": "CtaBox__isDark___3ilT7",
	"emailField": "CtaBox__emailField___1XZ32",
	"button": "CtaBox__button___kAaMB"
};
module.exports = ___CSS_LOADER_EXPORT___;
