// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextIconSection__container___184TI{padding:0 24px}.TextIconSection__inner___zzI55{margin-top:128px;margin-bottom:128px}@media (min-width:960px){.TextIconSection__inner___zzI55{display:flex;align-items:center;justify-content:space-between;grid-column-gap:24px;-moz-column-gap:24px;column-gap:24px}}@media (min-width:960px) and (min-width:720px){.TextIconSection__inner___zzI55{grid-column-gap:32px;-moz-column-gap:32px;column-gap:32px}}@media (min-width:960px) and (min-width:1280px){.TextIconSection__inner___zzI55{grid-column-gap:40px;-moz-column-gap:40px;column-gap:40px}}.TextIconSection__desc___2oDHt{flex-basis:0;flex-grow:1}.TextIconSection__desc___2oDHt a{margin-top:40px}@media (min-width:720px){.TextIconSection__desc___2oDHt{margin-top:0}}@media (min-width:960px){.TextIconSection__desc___2oDHt{max-width:474px;margin:0 73px}}.TextIconSection__description___1RWFM{margin-top:16px;color:#6b6651}.TextIconSection__view___50Fdm{flex-basis:0;flex-grow:1;margin-top:40px}@media (min-width:960px){.TextIconSection__view___50Fdm{max-width:620px;margin-top:0}}.TextIconSection__iconsList___3_S00{display:flex;flex-wrap:wrap;grid-gap:40px;gap:40px;justify-content:space-between}.TextIconSection__iconBlock___HLFRT{flex-basis:calc(50% - 40px);flex-grow:1;text-align:center}.TextIconSection__iconBlock___HLFRT:nth-child(n+5){display:none}@media (min-width:720px){.TextIconSection__iconBlock___HLFRT{flex-basis:calc(33% - 40px)}.TextIconSection__iconBlock___HLFRT:nth-child(n+5){display:block}}.TextIconSection__icon___8xfJI{height:48px}.TextIconSection__label___1tXkg{margin-top:16px;color:#2c2302}.TextIconSection__sublabel___QUvRA{margin-top:4px;color:inherit;font-weight:400;font-size:14px;line-height:20px;color:#96948a}.TextIconSection__iconsSubtext___5daaH{margin-top:40px}.TextIconSection__iconsSubtext___5daaH p{color:inherit;font-weight:400;font-size:14px;line-height:20px;color:#6b6651;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "TextIconSection__container___184TI",
	"inner": "TextIconSection__inner___zzI55",
	"desc": "TextIconSection__desc___2oDHt",
	"description": "TextIconSection__description___1RWFM",
	"view": "TextIconSection__view___50Fdm",
	"iconsList": "TextIconSection__iconsList___3_S00",
	"iconBlock": "TextIconSection__iconBlock___HLFRT",
	"icon": "TextIconSection__icon___8xfJI",
	"label": "TextIconSection__label___1tXkg",
	"sublabel": "TextIconSection__sublabel___QUvRA",
	"iconsSubtext": "TextIconSection__iconsSubtext___5daaH"
};
module.exports = ___CSS_LOADER_EXPORT___;
