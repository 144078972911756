<template>
  <div data-luko-tracking="Page-Index">
    <WaitlistModule v-if="hasWaitlist" :data="data" />
    <HomepageModule v-else :data="data" />
  </div>
</template>

<script>
import HomepageModule from '~/modules/Homepage'
import WaitlistModule from '~/modules/WaitlistPage'
import { formatMetaDatas } from '~/utils/metaData'

export default {
  name: 'HomePage',
  head() {
    const { title, subtitle } = this.data.body.find(
      (e) => e.slice_type === 'hero'
    ).primary
    const { open_graph_image } = this.metaDatas.find(
      (e) => e.slice_type === 'open_graph'
    ).primary

    const defaultMeta = {
      twitter_title: title[0].text,
      twitter_description: subtitle,
      twitter_image: open_graph_image.url,
    }

    const hreflangs = [
      { href: 'https://fr.luko.eu/', hreflang: 'fr-FR' },
      { href: 'https://fr.luko.eu/', hreflang: 'fr' },
      { href: 'https://fr.luko.eu/en/', hreflang: 'en-FR' },
      { href: 'https://fr.luko.eu/en/', hreflang: 'en' },
      { href: 'https://es.luko.eu/', hreflang: 'es-ES' },
      { href: 'https://es.luko.eu/', hreflang: 'es' },
      { href: 'https://es.luko.eu/en/', hreflang: 'en-ES' },
      { href: 'https://de.luko.eu/', hreflang: 'de-DE' },
      { href: 'https://de.luko.eu/', hreflang: 'de' },
      { href: 'https://de.luko.eu/en/', hreflang: 'en-DE' },
    ].map((h) => ({ ...h, rel: 'alternate' }))

    return formatMetaDatas(
      this.metaDatas,
      this.$route.path,
      defaultMeta,
      hreflangs
    )
  },

  components: {
    HomepageModule,
    WaitlistModule,
  },

  async asyncData({ app, $prismic, store, error }) {
    const hasWaitlist = process.env.HAS_WAITLIST === 'true'

    try {
      const { data, alternate_languages } = await $prismic.api.getSingle(
        hasWaitlist ? 'waitlist_page' : 'home_page',
        {
          lang: app.i18n.localeProperties.prismicIso,
        }
      )
      store.dispatch('setAlternateLanguages', alternate_languages)

      // Extract page settings from PageSettings slice.
      store.commit(
        'page/UPDATE',
        data.body.find((s) => s.slice_type === 'page_settings')
      )
      data.body = data.body.filter((s) => s.slice_type !== 'page_settings')

      return {
        hasWaitlist,
        alternate_languages,
        data,
        metaDatas: data.body1 || [],
      }
    } catch (e) {
      console.error(e)
      error({ statusCode: 500, message: 'Error in asyncData' })
    }
  },
}
</script>
<style lang="scss" module></style>
