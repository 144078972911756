// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "article.reverse{flex-direction:row-reverse}@media (max-width:959px){article.reverse{flex-direction:column}}@media (max-width:959px){.mobile_hidden{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px"
};
module.exports = ___CSS_LOADER_EXPORT___;
