<template>
  <section
    v-observe-visibility="isVisible"
    :class="$style.container"
    :style="`background-image:url(${primary.hero_background.url})`"
  >
    <Wrapper id="home-hero" :class="$style.inner">
      <div :class="$style.desc">
        <Title :class="$style.title" :title-params="primary.hero_title" />
        <p :class="$style.subtitle">
          {{ primary.hero_subtitle }}
        </p>
        <CtaBox
          v-if="primary.hero_cta_type !== 'None'"
          :placeholder="primary.hero_input_placeholder"
          :type="primary.hero_cta_type"
          :text="primary.hero_cta_text"
          :success-text="primary.hero_cta_text_success"
          :link="primary.hero_cta_link"
          :is-dark="primary.hero_is_dark"
          :class="$style.ctaBox"
        />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import Title from '~/components/Title'

import CtaBox from '../CtaBox'

export default {
  name: 'HomeHero',

  components: {
    Title,
    CtaBox,
  },

  props: {
    primary: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  max-width: inherit;
  margin-top: -64px;
  padding: 0 24px;

  background-color: #fcf7e8;
  background-repeat: repeat-x;
  background-position: bottom left;
  background-size: 2048px 594px;

  @include below(medium) {
    background-position-x: -270px;

    background-size: 1024px 287px;
  }

  @media (min-width: 2000px) {
    background-position-x: 500px;
    background-position-y: bottom;
  }

  @media (min-width: 3000px) {
    background-position-x: 1000px;
    background-position-y: bottom;
  }
}

.inner {
  display: flex;
  min-height: 630px;
  padding-top: 96px;

  @include above(medium) {
    min-height: 680px;
    padding-top: 144px;
  }
}

.desc {
  max-width: 720px;
  margin: 0 auto;
  @include above(medium) {
    margin: 0;
  }
}

.title h1 {
  max-width: 725px;
  margin-bottom: 16px;

  color: $gray-1000;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;

  @include above(medium) {
    font-size: 52px;
    line-height: 64px;
  }
}

.subtitle {
  max-width: 650px;
  margin: 0;

  color: $gray-800;

  font-size: 20px;
  line-height: 32px;

  @include below(medium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.ctaBox {
  margin-top: 56px;
}
</style>
