<template>
  <form :class="$style.formContainer" @submit.prevent="submit">
    <LkEmailField
      v-model="email"
      :class="$style.input"
      :placeholder="placeholder"
      required
    />
    <LkButton :icon="icon" :is-success="isButtonSuccess">
      {{ text }}
    </LkButton>
  </form>
</template>

<script>
import LkEmailField from '~/components/LkEmailField'

export default {
  name: 'NewsletterForm',

  components: {
    LkEmailField,
  },

  props: {
    buttonText: {
      type: String,
      default: '',
    },
    successText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isButtonSuccess: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      email: '',
      isSuccess: false,
    }
  },

  computed: {
    icon() {
      return this.isSuccess ? 'Checkmark' : 'Envelope'
    },

    text() {
      return this.isSuccess ? this.successText : this.buttonText
    },
  },

  methods: {
    submit() {
      if (window.appboy) {
        window.appboy.getUser().setEmail(this.email)
        window.appboy.logCustomEvent('Waitlist Spain')
        this.isSuccess = true

        setTimeout(() => {
          this.isSuccess = false
        }, 2000)

        window.LukoTracking.trackEvent({
          id: 'Waitlist Spain',
          properties: { subscriber: { email: this.email } },
        })
      } else {
        window.Didomi.notice.show()
      }
    },
  },
}
</script>

<style lang="scss" module>
.formContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include above(xsmall) {
    flex-direction: row;
    align-items: center;
  }
}

.input {
  flex-grow: 1;
  width: auto;
}
</style>
