// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HighlightSection__container___2y_fI{margin:80px 24px}.HighlightSection__inner___vjWMh{display:block;display:grid;grid-gap:24px;gap:24px;margin:0 auto}@media (min-width:960px){.HighlightSection__inner___vjWMh{grid-template-columns:repeat(2,1fr);grid-gap:40px;gap:40px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "HighlightSection__container___2y_fI",
	"inner": "HighlightSection__inner___vjWMh"
};
module.exports = ___CSS_LOADER_EXPORT___;
