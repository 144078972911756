<template>
  <div
    :class="[
      $style.card,
      { [$style.cardGreen]: isGreen },
      { [$style.hasCarousel]: carouselArray },
    ]"
  >
    <div :class="$style.cardHeading">
      <PImage
        v-if="hasLogo"
        :class="$style.logoHeadline"
        :image-object="cardObject.headline"
      />
      <p v-else :class="$style.headline">{{ cardObject.headline }}</p>
      <Title
        :class="$style.title"
        :title-params="cardObject.title"
        is-large-title
      />
      <PLink
        v-if="cardObject.ctaText"
        :class="$style.link"
        is-highlight
        :color="linkColor"
        simple-url="#"
        @on-click="showIntercom"
      >
        {{ cardObject.ctaText }}
      </PLink>
    </div>

    <PImage
      v-if="!carouselArray"
      :class="$style.image"
      :image-object="cardObject.image"
    />

    <div v-else :class="$style.carousel">
      <div :class="$style.carouselHeader">
        <button :class="$style.arrowButton" @click="decrementStep">
          <ArrowLeftIcon :class="leftArrowColor" height="24px" />
        </button>
        <PImage
          :class="$style.profilePicture"
          :image-object="currentStep.profile_picture"
        />
        <button :class="$style.arrowButton" @click="incrementStep">
          <ArrowRightIcon :class="rightArrowColor" height="24px" />
        </button>
      </div>
      <div :class="$style.carouselBody">
        <RichText :class="$style.content" :text-params="currentStep.text" />
        <RichText :class="$style.infos" :text-params="currentStep.infos" />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLeftIcon from 'LkIcons/ArrowLeft.vue'
import ArrowRightIcon from 'LkIcons/ArrowRight.vue'

import Title from '~/components/Title'
import RichText from '~/components/RichText'
import PLink from '~/components/PLink'
import PImage from '~/components/PImage'

export default {
  name: 'Card',

  components: {
    Title,
    RichText,
    PLink,
    PImage,
    ArrowLeftIcon,
    ArrowRightIcon,
  },

  props: {
    cardObject: {
      type: Object,
      default: () => ({}),
    },
    carouselArray: {
      type: Array,
      default: null,
    },
    isGreen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      carouselStep: 0,
    }
  },

  computed: {
    hasLogo() {
      // check if headline is an image or just a string
      return typeof this.cardObject.headline === 'object'
    },

    linkColor() {
      if (this.isGreen) return 'mint'
      return 'bluko'
    },

    leftArrowColor() {
      if (this.carouselStep - 1 < 0) return 'icon-mint-200'

      return 'icon-mint-500'
    },

    rightArrowColor() {
      if (this.carouselStep + 1 === this.carouselArray.length)
        return 'icon-mint-200'

      return 'icon-mint-500'
    },

    currentStep() {
      return this.carouselArray[this.carouselStep]
    },
  },

  methods: {
    decrementStep() {
      if (this.carouselStep - 1 < 0) return
      this.carouselStep--
    },

    incrementStep() {
      if (this.carouselStep + 1 === this.carouselArray.length) return
      this.carouselStep++
    },

    showIntercom() {
      if (window.Intercom) {
        window.Intercom('show')
      } else {
        window.Didomi.notice.show()
      }
    },
  },
}
</script>

<style lang="scss" module>
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 8px;
  overflow: hidden;

  text-align: center;

  background-color: #fcf7e8;

  &.cardGreen {
    background-color: $mint-50;
  }

  &.hasCarousel {
    display: block;
    padding: 32px 24px;

    .cardHeading {
      padding: 0;
    }

    @include above(small) {
      padding: 80px 73px 40px;
    }
  }
}

.cardHeading {
  padding: 32px 24px;

  @include above(small) {
    padding: 80px 73px 40px;
  }
}

.headline {
  @include typo-sub-text;
  color: $gray-700;
}

.logoHeadline {
  height: 20px;
}

.title {
  margin-top: 16px;
}

.link {
  display: block;
  margin: 32px auto 0;
  margin-top: 32px;
}

.image {
  display: block;
  justify-self: flex-end;
  width: 100%;
}

.carousel {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid $mint-100;

  color: $mint-800;

  @include above(small) {
    margin-top: 40px;
    padding-top: 40px;
  }
}

.carouselHeader {
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: center;
}

.arrowButton {
  padding: 0;
  border: 0;

  background-color: transparent;

  cursor: pointer;
}

.profilePicture {
  width: 48px;
  height: 48px;
  border-radius: 24px;
}

.carouselBody {
  margin-top: 24px;
}

.infos {
  margin-top: 24px;

  @include typo-sub-text;

  strong {
    color: $mint-1000;
  }
}
</style>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
