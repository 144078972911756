<template>
  <p data-luko-tracking="QuoteSlice">
    <em>{{ quote }}</em>
    {{ author }}
  </p>
</template>

<script>
export default {
  name: 'QuoteSlice',
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    author() {
      return this.slice.primary.name_of_the_author[0].text
    },
    quote() {
      return this.slice.primary.quote[0].text
    },
  },
}
</script>

<style lang="scss" module></style>
