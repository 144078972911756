<template>
  <section data-luko-tracking="ContactSection" :class="$style.container">
    <Wrapper :class="$style.inner">
      <article
        v-for="(item, itemIndex) in items"
        :key="itemIndex"
        :class="[
          { [$style.box]: item.icon && item.icon.url },
          { [$style.hide]: !item.icon || !item.icon.url },
        ]"
      >
        <div :class="$style.itemWrapper">
          <PImage :image-object="item.icon" :class="$style.icon" />
          <RichText
            :text-params="item.description"
            :class="$style.description"
          />
          <PLink
            v-if="isMailTo(item.cta_type)"
            :simple-url="mailTo(item.cta_email)"
            :class="$style.cta"
          >
            <LkButton :is-light="!isCenter(itemIndex)">
              {{ item.cta_text }}
            </LkButton>
          </PLink>
          <PLink v-else :link-object="item.cta_link" :class="$style.cta">
            <LkButton :is-light="!isCenter(itemIndex)">
              {{ item.cta_text }}
            </LkButton>
          </PLink>
        </div>
      </article>
    </Wrapper>
  </section>
</template>

<script>
import RichText from '~/components/RichText'
import PLink from '~/components/PLink'

export default {
  components: {
    RichText,
    PLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    items() {
      return this.slice.items
    },
  },

  methods: {
    isCenter(index) {
      return index === 1
    },

    isMailTo(type) {
      return type === 'mail_to'
    },

    mailTo(email) {
      return `mailto:${email}`
    },
  },
}
</script>

<style lang="scss" module>
.container {
  width: 100%;

  background-color: #fffbf2; // $cream-400 doesn't show same color
}
.inner {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  align-items: center;
  justify-content: center;
  max-width: 1280px;
  min-height: 512px;
  padding: 40px 0;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 410px;
  border-radius: 8px;

  text-align: center;

  background-color: white;

  @include below(large) {
    margin: 0 24px;
  }

  &:nth-child(1),
  &:nth-child(3) {
    @include above(small) {
      width: 480px;
      min-height: 370px;
    }
    @include above(large) {
      width: 360px;
      min-height: 312px;
    }
  }

  &:nth-child(3) {
    order: 2;
    @include above(large) {
      order: 3;
    }
  }

  // center element
  &:nth-child(2) {
    order: 3;
    .icon {
      width: 135px;
      height: 70px;
    }
    @include above(large) {
      order: 2;
      max-width: 480px;
      min-height: 384px;
    }
  }
}

.itemWrapper {
  padding: 24px 32px;
}

.description {
  h3 {
    @include typo-headline-bold;
  }
  h3:first-of-type {
    margin-top: 16px;
  }
  p {
    @include typo-body;
    margin-top: 8px;

    color: $gray-700;
  }
}

.icon {
  width: 48px;
  height: 48px;
}

.cta {
  margin-top: 32px;

  text-decoration: none;
}

.hide {
  display: none;
}
</style>
