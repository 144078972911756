<template>
  <section :class="$style.container">
    <Wrapper :class="$style.inner">
      <div :class="$style.content">
        <PImage :class="$style.icon" :image-object="primary.headline_icon" />
        <p>{{ primary.headline }}</p>
      </div>
      <LkButton
        :class="$style.button"
        :icon="primary.cta_icon"
        href="#newsletterSection"
      >
        {{ primary.cta_text }}
      </LkButton>
    </Wrapper>
  </section>
</template>

<script>
export default {
  name: 'HomeTermination',

  props: {
    primary: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  justify-content: center;
  margin: 24px 0 126px;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 840px;
  margin: 0 24px;
  padding: 32px;
  border: solid 1px $gray-100;
  border-radius: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  @include above(small) {
    flex-direction: row;
  }

  p {
    @include typo-headline;
    color: $gray-700;
    text-align: center;

    @include above(small) {
      text-align: left;
    }
  }
}

.icon {
  width: 24px;
  height: 24px;
}

.button {
  margin-top: 24px;
}
</style>
