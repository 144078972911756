<template>
  <RichText
    :class="$style.richtext"
    :text-params="slice.primary.text"
    data-luko-tracking="RichTextSlice"
  />
</template>

<script>
import RichText from '~/components/RichText'

export default {
  name: 'RichTextSlice',
  components: {
    RichText,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" module>
.richtext {
  max-width: 840px;
  h1 {
    margin: 64px 0 8px 0;

    color: $gray-1000;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
  }

  h2 {
    margin: 32px 0 8px 0;

    color: $gray-700;

    font-weight: normal;
    font-size: 28px;
    line-height: 40px;
  }

  h3 {
    margin: 24px 0 8px 0;

    color: $gray-1000;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
  }

  p {
    margin: 8px 0;

    color: $gray-1000;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  a {
    color: $gray-1000;
  }

  ul {
    display: block;
    margin-left: 15px;

    li {
      display: list-item;
      margin: 8px 0;

      color: $gray-1000;
      font-size: 16px;
      line-height: 24px;

      text-align: -webkit-match-parent;
    }
  }
}
</style>
