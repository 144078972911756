// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BundleProductSlice__container___1rRNB{margin:128px 0;padding:0 24px}.BundleProductSlice__list___2GshZ{display:grid;grid-template-columns:repeat(1,1fr);grid-gap:40px;gap:40px;margin-top:24px}@media (min-width:540px){.BundleProductSlice__list___2GshZ{grid-template-columns:repeat(2,1fr)}}@media (min-width:720px){.BundleProductSlice__list___2GshZ{grid-template-columns:repeat(3,1fr)}}@media (min-width:960px){.BundleProductSlice__list___2GshZ{grid-template-columns:repeat(4,1fr)}}@media (min-width:720px){.BundleProductSlice__item___2QELf:nth-child(n+4){display:none}}@media (min-width:960px){.BundleProductSlice__item___2QELf:nth-child(n+4){display:block}}.BundleProductSlice__thumbnail___1kmqQ{height:160px;border-radius:8px;-o-object-fit:cover;object-fit:cover}.BundleProductSlice__link___3U8BM,.BundleProductSlice__thumbnail___1kmqQ{display:block;width:100%}.BundleProductSlice__link___3U8BM{color:#2c2302;text-decoration:none}.BundleProductSlice__content___3kXMN{display:flex;grid-gap:8px;gap:8px;align-items:flex-start;margin-top:8px}.BundleProductSlice__icon___3ve_h{width:24px;height:24px;-o-object-fit:cover;object-fit:cover}.BundleProductSlice__thumbnailTitle___1GqJg{margin-left:8px;font-weight:500}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "BundleProductSlice__container___1rRNB",
	"list": "BundleProductSlice__list___2GshZ",
	"item": "BundleProductSlice__item___2QELf",
	"thumbnail": "BundleProductSlice__thumbnail___1kmqQ",
	"link": "BundleProductSlice__link___3U8BM",
	"content": "BundleProductSlice__content___3kXMN",
	"icon": "BundleProductSlice__icon___3ve_h",
	"thumbnailTitle": "BundleProductSlice__thumbnailTitle___1GqJg"
};
module.exports = ___CSS_LOADER_EXPORT___;
