<template>
  <section
    v-observe-visibility="isVisible"
    data-luko-tracking="Hero"
    :class="[[$style.heroContainer], { [$style.isDark]: isDark }]"
  >
    <Wrapper :class="$style.inner">
      <div :class="$style.desc">
        <Breadcrumb
          v-if="content.show_breadcrumb"
          :class="$style.breadcrumb"
          :is-dark="isDark"
          :custom-name="content.title[0].text"
        />
        <div :class="$style.titleContainer">
          <Title :class="$style.title" :title-params="content.title" />
          <span v-if="content.tag" :class="$style.tag">{{ content.tag }}</span>
        </div>
        <div
          v-if="content.cobranded_logo && content.cobranded_logo.url"
          :class="$style.cobranded"
        >
          <p>
            {{ withText }}
          </p>
          <PImage :image-object="content.cobranded_logo" />
        </div>
        <RichText
          :class="$style.description"
          :text-params="content.description"
        />
        <CtaBox
          v-if="content.cta_type !== 'None'"
          :class="$style.ctaContainer"
          :placeholder="content.placeholder"
          :type="content.cta_type"
          :text="content.cta_text"
          :link="content.cta_link"
          :to-onboarding="content.cta_to_onboarding"
          :is-dark="isDark"
          :apps="apps"
        />
        <RichText
          v-if="content.additional_description"
          :class="$style.otherDescription"
          :text-params="content.additional_description"
        />
        <ul v-if="content.show_link_list" :class="$style.linkList">
          <li
            v-for="(link, linkIndex) in items"
            :key="linkIndex"
            :class="$style.linkItem"
          >
            <PLink
              :link-object="link.link"
              :targeted-section="link.targeted_section"
              is-highlight
              color="bluko"
              >{{ link.text }}</PLink
            >
          </li>
        </ul>
      </div>
      <div :class="$style.view">
        <PImage :class="$style.image" :image-object="content.background" />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import { mapMutations } from 'vuex'

import { getOnboardingLink } from '~/mixins/getOnboardingLink'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import PLink from '~/components/PLink'
import PImage from '~/components/PImage'
import Breadcrumb from '~/components/Breadcrumb'

import { CtaBox } from './components'

export default {
  name: 'Hero',

  components: {
    Title,
    RichText,
    PLink,
    PImage,
    CtaBox,
    Breadcrumb,
  },

  mixins: [getFormatedLink, getOnboardingLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },

    ctaTarget() {
      return this.content.cta_to_onboarding
        ? this.getOnboardingLink()
        : this.getFormatedLink(this.content.cta_link)
    },

    withText() {
      return this.$i18n.locale === 'fr' ? 'avec' : 'with'
    },
    isDark() {
      return this.$store.state.page.settings.theme === 'dark'
    },
    apps() {
      return [
        {
          image: this.content.image_app_store,
          link: this.content.cta_app_store,
        },
        {
          image: this.content.image_google_play,
          link: this.content.cta_google_play,
        },
      ]
    },
  },

  methods: {
    ...mapMutations(['SET_LIGHT_HEADER']),
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>

<style lang="scss" module>
.heroContainer {
  position: relative;
  z-index: 2;

  margin-top: -64px;
  padding: 24px;
  padding-top: 64px;

  background-color: #fcf7e8;

  @include below(xsmall) {
    background-image: none !important;
  }

  @include above(medium) {
    min-height: 680px;
    padding: 46px 80px;
    padding-top: 64px;
  }
}

.inner {
  display: flex;
  padding-top: 24px;

  @include above(medium) {
    display: flex;
    padding-top: 56px;
  }

  @include below(medium) {
    flex-direction: column;
  }
}

.desc {
  @include above(medium) {
    width: 50%;
    max-width: 600px;
  }
}

.breadcrumb {
  margin-bottom: 16px;
}

.title {
  display: inline;
  margin-top: 16px;

  @include typo-mega-title;

  h1 {
    display: inline;
  }

  @include below(small) {
    font-size: 40px;
    line-height: 56px;
  }
}

.tag {
  display: inline-block;
  margin-top: 10px;

  padding: 8px 16px;
  border-radius: 20px;

  background-color: $gold-500;

  transform: translateY(-10px);
  @include typo-body;

  @include below(small) {
    display: none;
  }
}

.cobranded {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  margin-top: 16px;

  @include typo-headline;
}

.description p {
  @include typo-headline;
  margin-top: 16px;

  color: $gray-800;
}

.ctaContainer {
  margin-top: 40px;
}

.otherDescription {
  margin-top: 16px;
}

.view {
  height: 256px;
  margin-top: 16px;
  margin-right: -24px;
  margin-bottom: -24px;
  border-bottom-left-radius: 128px;
  overflow: hidden;

  @include above(medium) {
    position: absolute;
    right: 0;
    bottom: 0;

    width: 45%;
    height: calc(100% - 100px);
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;

    border-bottom-left-radius: 150px;
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 80%;
}

// .viewMobile {
//   margin-top: 32px;
//   margin-right: -24px;
//   margin-bottom: -24px;
//   margin-left: -24px;

//   @include above(xsmall) {
//     display: none;
//   }
// }

// .image {
//   max-width: 100%;

//   opacity: 0;

//   @include below(medium) {
//     max-height: 300px;
//   }
// }

.isDark {
  background-color: $bluko-800;

  .title {
    color: white;
  }

  .cobranded {
    color: $bluko-300;
  }

  .description p,
  .otherDescription {
    color: $bluko-200;
  }
}

.linkList {
  margin-top: 40px;
}

.linkItem {
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}
</style>
