<template>
  <section :class="$style.container" data-luko-tracking="HorizontalListSlice">
    <Wrapper :class="$style.inner">
      <Title
        :title-params="content.title"
        is-extra-title
        :class="$style.title"
      />
      <div :class="$style.listContainer">
        <div
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          :class="$style.item"
        >
          <PImage :image-object="item.icon" :class="$style.icon" />
          <RichText :class="$style.text" :text-params="item.text" />
          <PLink
            v-if="item.link_url.url && item.link_text"
            :link-object="item.link_url"
            is-highlight
            color="bluko"
            :class="$style.link"
          >
            {{ item.link_text }}
          </PLink>
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import Title from '~/components/Title'
import PImage from '~/components/PImage'
import PLink from '~/components/PLink'
import RichText from '~/components/RichText'

export default {
  name: 'HorizontalListSlice',

  components: {
    Title,
    PImage,
    PLink,
    RichText,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 128px 0;
  padding: 0 24px;
}

.inner {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.title {
  text-align: center;
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 80px;

  @include above(small) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.item {
  flex-grow: 1;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  text-align: center;

  @include above(small) {
    width: 45%;
  }
  @include above(medium) {
    width: 30%;
  }
  @include above(medium) {
    width: auto;
  }
}

.icon {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  object-fit: cover;
}

.text {
  margin-top: 24px;

  h3,
  h4 {
    @include typo-headline;
    font-weight: 500;
  }

  p {
    margin-top: 8px;
  }
}

.link {
  margin-top: 24px;
}
</style>
