// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArticlesList__questionsContainer___2cW5h{position:relative;margin:128px 24px}.ArticlesList__title___28vFi{color:inherit;font-weight:500;font-size:32px;line-height:48px}.ArticlesList__list___2B2pd{display:grid;grid-gap:40px;gap:40px;margin-top:56px}@media (min-width:720px){.ArticlesList__list___2B2pd{grid-template-columns:repeat(2,1fr)}}.ArticlesList__article___1KrDF{max-width:none}@media (max-width:1279px){.ArticlesList__article___1KrDF>div{flex-direction:column!important;align-items:flex-start!important}}.ArticlesList__titleContainer___2sUuG{display:flex;flex-direction:row;justify-content:space-between}.ArticlesList__titleContainer___2sUuG .ArticlesList__desktopCta___3Yzlm{display:none}@media (min-width:720px){.ArticlesList__titleContainer___2sUuG .ArticlesList__desktopCta___3Yzlm{display:block}}.ArticlesList__mobileCta___z5tIz{margin:24px auto;text-align:center}@media (min-width:720px){.ArticlesList__mobileCta___z5tIz{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"questionsContainer": "ArticlesList__questionsContainer___2cW5h",
	"title": "ArticlesList__title___28vFi",
	"list": "ArticlesList__list___2B2pd",
	"article": "ArticlesList__article___1KrDF",
	"titleContainer": "ArticlesList__titleContainer___2sUuG",
	"desktopCta": "ArticlesList__desktopCta___3Yzlm",
	"mobileCta": "ArticlesList__mobileCta___z5tIz"
};
module.exports = ___CSS_LOADER_EXPORT___;
