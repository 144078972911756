// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hero__heroContainer___8L-xJ{position:relative;min-height:680px;margin-top:-64px;padding:64px 24px 24px;background-color:#fcf7e8;background-repeat:no-repeat;background-position:100% 100%}@media (max-width:539px){.Hero__heroContainer___8L-xJ{background-image:none!important}}@media (min-width:960px){.Hero__heroContainer___8L-xJ{padding:64px 80px 46px}}.Hero__inner___I2Gfm{padding-top:24px;border-top:1px solid #eae9e3}@media (min-width:960px){.Hero__inner___I2Gfm{display:flex;padding-top:56px}}@media (min-width:960px){.Hero__desc___1KcMa{width:50%;max-width:620px}}.Hero__title___1QcaQ{margin-top:16px;color:inherit;font-weight:500;font-size:48px;line-height:64px}.Hero__description___3aWrc p{margin-top:16px;color:inherit;font-weight:400;font-size:20px;line-height:32px;color:#6b6651}.Hero__button___2zi86{width:100%;margin-top:40px;color:#fff;font-weight:500}@media (min-width:720px){.Hero__button___2zi86{width:auto}}.Hero__linkList___1xJvX{margin-top:24px}.Hero__linkList___1xJvX,.Hero__linkList___1xJvX li{padding:8px 0}.Hero__view___1w1Uf{display:none}@media (min-width:540px){.Hero__view___1w1Uf{display:block;width:90%;max-width:720px;margin:32px -24px -24px auto}}@media (min-width:720px){.Hero__view___1w1Uf{width:70%}}@media (min-width:960px){.Hero__view___1w1Uf{position:absolute;right:0;bottom:0;width:50%;margin:0}}.Hero__viewMobile___2xFFa{margin:32px -24px -24px}@media (min-width:540px){.Hero__viewMobile___2xFFa{display:none}}.Hero__image___2vMX4{display:block;width:100%;height:100%;-o-object-fit:cover;object-fit:cover;-o-object-position:80%;object-position:80%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"heroContainer": "Hero__heroContainer___8L-xJ",
	"inner": "Hero__inner___I2Gfm",
	"desc": "Hero__desc___1KcMa",
	"title": "Hero__title___1QcaQ",
	"description": "Hero__description___3aWrc",
	"button": "Hero__button___2zi86",
	"linkList": "Hero__linkList___1xJvX",
	"view": "Hero__view___1w1Uf",
	"viewMobile": "Hero__viewMobile___2xFFa",
	"image": "Hero__image___2vMX4"
};
module.exports = ___CSS_LOADER_EXPORT___;
