// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RichTextSlice__richtext___p-EEE{max-width:840px}.RichTextSlice__richtext___p-EEE h1{margin:64px 0 8px;color:#2c2302;font-weight:500;font-size:32px;line-height:48px}.RichTextSlice__richtext___p-EEE h2{margin:32px 0 8px;color:#6b6651;font-weight:400;font-size:28px;line-height:40px}.RichTextSlice__richtext___p-EEE h3{margin:24px 0 8px;font-size:20px;line-height:32px}.RichTextSlice__richtext___p-EEE h3,.RichTextSlice__richtext___p-EEE p{color:#2c2302;font-weight:400}.RichTextSlice__richtext___p-EEE p{margin:8px 0;font-size:16px;line-height:24px}.RichTextSlice__richtext___p-EEE a{color:#2c2302}.RichTextSlice__richtext___p-EEE ul{display:block;margin-left:15px}.RichTextSlice__richtext___p-EEE ul li{display:list-item;margin:8px 0;color:#2c2302;font-size:16px;line-height:24px;text-align:-webkit-match-parent}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"richtext": "RichTextSlice__richtext___p-EEE"
};
module.exports = ___CSS_LOADER_EXPORT___;
