<template>
  <section
    v-observe-visibility="isVisible"
    :class="$style.heroContainer"
    data-luko-tracking="HeroLegacy"
  >
    <Wrapper :class="$style.inner">
      <div :class="$style.desc">
        <Breadcrumb
          v-if="content.show_breadcrumb"
          :custom-name="categoryName"
          :category-uid="categoryUid"
        />
        <Title :class="$style.title" :title-params="content.title" />
        <RichText
          :class="$style.description"
          :text-params="content.description"
        />
        <LkButton
          v-if="
            content.show_cta &&
            (content.cta_to_onboarding ||
              (content.cta_link && content.cta_link.url))
          "
          :class="$style.button"
          :href="ctaTarget"
        >
          {{ content.cta_text }}
        </LkButton>

        <!-- section id cta -->
        <LkButton
          v-if="content.show_cta && content.cta_section"
          :class="$style.button"
          :href="content.cta_section"
        >
          {{ content.cta_text }}
        </LkButton>

        <ul v-if="slice.show_link_list" :class="$style.linkList">
          <li v-for="(link, linkIndex) in linkList" :key="linkIndex">
            <PLink :link-object="link.link" is-highlight color="bluko">{{
              link.text
            }}</PLink>
          </li>
        </ul>
      </div>
      <div :class="$style.view">
        <PImage :class="$style.image" :image-object="content.background" />
      </div>
      <div v-if="content.image_mobile" :class="$style.viewMobile">
        <PImage :class="$style.image" :image-object="content.image_mobile" />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import PLink from '~/components/PLink'
import Breadcrumb from '~/components/Breadcrumb'
import PImage from '~/components/PImage'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import { getOnboardingLink } from '~/mixins/getOnboardingLink'

export default {
  name: 'Hero',

  components: {
    Title,
    RichText,
    PLink,
    Breadcrumb,
    PImage,
  },

  mixins: [getFormatedLink, getOnboardingLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
    categoryName: {
      type: String,
      default: '',
    },
    categoryUid: {
      type: String,
      default: '',
    },
  },

  computed: {
    content() {
      return this.slice.primary
    },

    linkList() {
      return this.slice.items
    },

    background() {
      return `background-image: url(${this.content.background?.url})`
    },

    ctaTarget() {
      return this.content.cta_to_onboarding
        ? this.getOnboardingLink()
        : this.getFormatedLink(this.content.cta_link)
    },
  },

  methods: {
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>

<style lang="scss" module>
.heroContainer {
  position: relative;

  min-height: 680px;
  margin-top: -64px;
  padding: 24px;
  padding-top: 64px;

  background-color: #fcf7e8;
  background-repeat: no-repeat;

  background-position: right bottom;

  @include below(xsmall) {
    background-image: none !important;
  }
  @include above(medium) {
    padding: 46px 80px;
    padding-top: 64px;
  }
}

.inner {
  padding-top: 24px;
  border-top: solid 1px $gray-100;

  @include above(medium) {
    display: flex;
    padding-top: 56px;
  }
}

.desc {
  @include above(medium) {
    width: 50%;
    max-width: 620px;
  }
}

.title {
  margin-top: 16px;

  @include typo-mega-title;
}
.description p {
  margin-top: 16px;

  @include typo-headline;
  color: $gray-700;
}

.button {
  width: 100%;
  margin-top: 40px;

  color: $white;
  font-weight: 500;

  @include above(small) {
    width: auto;
  }
}

.linkList {
  margin-top: 24px;
  padding: 8px 0;

  li {
    padding: 8px 0;
  }
}
.view {
  display: none;

  @include above(xsmall) {
    display: block;
    width: 90%;
    max-width: 720px;
    margin: 32px -24px -24px auto;
  }

  @include above(small) {
    width: 70%;
  }

  @include above(medium) {
    position: absolute;
    right: 0;
    bottom: 0;

    width: 50%;
    margin: 0;
  }
}

.viewMobile {
  margin-top: 32px;
  margin-right: -24px;
  margin-bottom: -24px;
  margin-left: -24px;

  @include above(xsmall) {
    display: none;
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 80%;
}
</style>
