<template>
  <section :class="$style.container" data-luko-tracking="TextIconSection">
    <Wrapper :class="$style.inner">
      <div :class="$style.desc">
        <Title
          :class="$style.contentTitle"
          is-large-title
          :title-params="content.title"
        />
        <RichText :text-params="content.text" :class="$style.description" />
        <PLink :link-object="content.link_url" is-highlight color="bluko">
          {{ content.link_text }}
        </PLink>
      </div>
      <div :class="$style.view">
        <ul :class="$style.iconsList">
          <li
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
            :class="$style.iconBlock"
          >
            <PImage :image-object="item.icon" :class="$style.icon" />
            <p :class="$style.label">{{ item.label }}</p>
            <p v-if="item.sublabel" :class="$style.sublabel">
              {{ item.sublabel }}
            </p>
          </li>
        </ul>
        <RichText
          :text-params="content.icons_subtext"
          :class="$style.iconsSubtext"
        />
      </div>
    </Wrapper>
  </section>
</template>

<script>
import RichText from '~/components/RichText'
import Title from '~/components/Title'
import PImage from '~/components/PImage'
import PLink from '~/components/PLink'

export default {
  name: 'TextIconSection',

  components: {
    RichText,
    Title,
    PImage,
    PLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 0 24px;
}

.inner {
  margin-top: 128px;
  margin-bottom: 128px;

  @include above(medium) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    column-gap: 24px;

    @include above(small) {
      column-gap: 32px;
    }
    @include above(large) {
      column-gap: 40px;
    }
  }
}

.desc {
  flex-basis: 0;
  flex-grow: 1;

  a {
    margin-top: 40px;
  }

  @include above(small) {
    margin-top: 0;
  }

  @include above(medium) {
    max-width: 474px;
    margin: 0 73px;
  }
}

.description {
  margin-top: 16px;

  color: $gray-700;
}

.view {
  flex-basis: 0;
  flex-grow: 1;
  margin-top: 40px;

  @include above(medium) {
    max-width: 620px;
    margin-top: 0px;
  }
}

.iconsList {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-between;
}

.iconBlock {
  flex-basis: calc(50% - 40px);
  flex-grow: 1;

  text-align: center;

  &:nth-child(n + 5) {
    display: none;
  }

  @include above(small) {
    flex-basis: calc(33% - 40px);
    &:nth-child(n + 5) {
      display: block;
    }
  }
}

.icon {
  height: 48px;
}

.label {
  margin-top: 16px;

  color: $gray-1000;
}

.sublabel {
  margin-top: 4px;

  @include typo-sub-text;
  color: $gray-500;
}

.iconsSubtext {
  margin-top: 40px;

  p {
    @include typo-sub-text;
    color: $gray-700;

    text-align: center;
  }
}
</style>
