<template>
  <div :class="$style.tableContainer" data-luko-tracking="TableSlice">
    <table :class="$style.table">
      <tr v-for="(item, index) in slice.items" :key="index">
        <td>
          <RichText :class="$style.richtext" :text-params="item.col_1" />
        </td>
        <td>
          <RichText :class="$style.richtext" :text-params="item.col_2" />
        </td>
        <td>
          <RichText :class="$style.richtext" :text-params="item.col_3" />
        </td>
        <td>
          <RichText :class="$style.richtext" :text-params="item.col_4" />
        </td>
        <td>
          <RichText :class="$style.richtext" :text-params="item.col_5" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import RichText from '~/components/RichText'

export default {
  name: 'TableSlice',
  components: {
    RichText,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" module>
.tableContainer {
  overflow: scroll;
}

.table {
  max-width: 840px;
  margin-top: 32px;

  word-break: break-word;
  td {
    width: calc(100% / 5);
    padding: 5px;
  }
  h1 {
    margin: 64px 0 8px 0;

    color: $gray-1000;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
  }

  h2 {
    margin: 32px 0 8px 0;

    color: $gray-700;

    font-weight: normal;
    font-size: 28px;
    line-height: 40px;
  }

  h3 {
    margin: 24px 0 8px 0;

    color: $gray-1000;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
  }

  p {
    margin: 8px 0;

    color: $gray-1000;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  a {
    color: $gray-1000;
  }

  ul {
    display: block;
    margin-left: 40px;

    list-style: outside;

    list-style-type: disc;
    li {
      display: list-item;
      margin: 8px 0;

      color: $gray-1000;
      font-size: 16px;
      line-height: 24px;

      text-align: -webkit-match-parent;
    }
  }
}
</style>
