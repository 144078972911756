// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmbedCalendly__container___17jr_{position:relative;display:flex;align-items:center;min-width:320px;height:1180px;padding-top:80px}@media screen and (max-width:1000px){.EmbedCalendly__container___17jr_{height:1400px}}@media (max-width:719px){.EmbedCalendly__container___17jr_{display:none}}.EmbedCalendly__container___17jr_ iframe{position:relative;z-index:2}.EmbedCalendly__loading___c_itR{position:absolute;top:30%;left:50%;display:flex;align-items:center;transform:translateX(-50%)}.EmbedCalendly__loader___3xn_U{width:35px;height:35px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "EmbedCalendly__container___17jr_",
	"loading": "EmbedCalendly__loading___c_itR",
	"loader": "EmbedCalendly__loader___3xn_U"
};
module.exports = ___CSS_LOADER_EXPORT___;
