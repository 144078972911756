// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FaqSection__questionsContainer___3Wv9M{position:relative;background-color:#fcf7e8}.FaqSection__inner___1dnbg{display:flex;flex-direction:column;padding:40px 24px}@media (min-width:960px){.FaqSection__inner___1dnbg{padding:80px 24px}}.FaqSection__title___2H0vg{color:inherit;font-weight:500;font-size:32px;line-height:48px;max-width:700px;margin:0 auto;text-align:center}.FaqSection__list___1JkgX{margin-top:40px}@media (min-width:960px){.FaqSection__list___1JkgX{display:flex;flex-wrap:wrap;grid-gap:40px;gap:40px;margin-top:56px}}.FaqSection__item___3T_wa:not(:first-child){margin-top:48px}@media (min-width:960px){.FaqSection__item___3T_wa{flex-basis:calc(50% - 40px)}.FaqSection__item___3T_wa:not(:first-child){margin-top:0}}.FaqSection__itemTitle___aUK87{color:inherit;font-weight:400;font-size:20px;line-height:32px;font-weight:500}.FaqSection__itemContent___2AWbR{margin-top:16px;color:#6b6651}.FaqSection__link___39ahA{margin-top:8px}.FaqSection__button___AvS55{margin:80px auto 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"questionsContainer": "FaqSection__questionsContainer___3Wv9M",
	"inner": "FaqSection__inner___1dnbg",
	"title": "FaqSection__title___2H0vg",
	"list": "FaqSection__list___1JkgX",
	"item": "FaqSection__item___3T_wa",
	"itemTitle": "FaqSection__itemTitle___aUK87",
	"itemContent": "FaqSection__itemContent___2AWbR",
	"link": "FaqSection__link___39ahA",
	"button": "FaqSection__button___AvS55"
};
module.exports = ___CSS_LOADER_EXPORT___;
