// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableSlice__tableContainer___3T2aH{overflow:scroll}.TableSlice__table___3TTfe{max-width:840px;margin-top:32px;word-break:break-word}.TableSlice__table___3TTfe td{width:20%;padding:5px}.TableSlice__table___3TTfe h1{margin:64px 0 8px;color:#2c2302;font-weight:500;font-size:32px;line-height:48px}.TableSlice__table___3TTfe h2{margin:32px 0 8px;color:#6b6651;font-weight:400;font-size:28px;line-height:40px}.TableSlice__table___3TTfe h3{margin:24px 0 8px;font-size:20px;line-height:32px}.TableSlice__table___3TTfe h3,.TableSlice__table___3TTfe p{color:#2c2302;font-weight:400}.TableSlice__table___3TTfe p{margin:8px 0;font-size:16px;line-height:24px}.TableSlice__table___3TTfe a{color:#2c2302}.TableSlice__table___3TTfe ul{display:block;margin-left:40px;list-style:outside;list-style-type:disc}.TableSlice__table___3TTfe ul li{display:list-item;margin:8px 0;color:#2c2302;font-size:16px;line-height:24px;text-align:-webkit-match-parent}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"tableContainer": "TableSlice__tableContainer___3T2aH",
	"table": "TableSlice__table___3TTfe"
};
module.exports = ___CSS_LOADER_EXPORT___;
