<template>
  <section :class="$style.container" data-luko-tracking="MediaCarrousel">
    <Wrapper
      v-observe-visibility="{
        callback: sectionOnScreen,
        intersection: {
          threshold: 1,
        },
      }"
      :class="$style.inner"
    >
      <div :class="$style.desc">
        <Title
          :class="$style.title"
          :title-params="primary.title"
          is-extra-title
        />
        <RichText
          :class="$style.description"
          :text-params="primary.description"
        />
        <div :class="$style.itemsContainer">
          <div
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
            :class="[$style.item, { [$style.itemActive]: isActive(itemIndex) }]"
            @click="setActiveIndex(itemIndex)"
            @mouseenter="onMouseEnter(itemIndex)"
            @mouseleave="onMouseLeave(itemIndex)"
          >
            <div :class="$style.iconContainer">
              <RadialProgressBar
                :class="$style.circleProgress"
                :completed-steps="getProgress(itemIndex)"
                :animate-speed="getProgressSpeed(itemIndex)"
                :diameter="72"
                :total-steps="100"
                inner-stroke-color="transparent"
                :stroke-width="4"
              >
                <div
                  :class="[
                    $style.itemIcon,
                    { [$style.itemActive]: isActive(itemIndex) },
                    {
                      [$style.hide]: itemIndex >= mediaItems.length,
                    },
                  ]"
                >
                  {{ itemIndex + 1 }}
                </div>
              </RadialProgressBar>
            </div>
            <div :class="$style.infos">
              <RichText
                :text-params="item.description"
                :class="$style.itemDescription"
              />
            </div>
          </div>
        </div>
      </div>

      <div :class="$style.view">
        <template v-for="(item, itemIndex) in items">
          <template v-if="item.video && item.video.url">
            <video
              v-show="isActive(itemIndex)"
              :key="'video-' + itemIndex"
              :ref="'video-' + itemIndex"
              :class="[$style.mediaContainer]"
              muted="muted"
            >
              <source :src="item.video.url" type="video/mp4" />
            </video>
          </template>
          <template v-if="item.picture && item.picture.url">
            <PImage
              v-show="isActive(itemIndex)"
              :key="'image-' + itemIndex"
              :ref="'image-' + itemIndex"
              :image-object="item.picture"
              :class="[$style.mediaContainer]"
            />
          </template>
        </template>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress/src/RadialProgressBar.vue'

export default {
  name: 'ScrollableList',

  components: {
    RadialProgressBar,
  },

  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
      hasPlayedOnce: false,
      activeIndex: 0,
      prog: {},
      timeoutFct: null,
      activeVideoProgression: 0,
      timerFct: null,
    }
  },

  computed: {
    activeVideo() {
      const video = this.$refs['video-' + this.activeIndex]
      return video
        ? video[0]
        : {
            duration: 0,
            play() {},
            pause() {},
          }
    },

    progressions: {
      get() {
        return this.prog
      },
      set(newValue) {
        this.prog = newValue
      },
    },

    itemDuration() {
      return Math.round(this.activeVideo.duration + 4)
    },

    mediaItems() {
      return this.items.filter((item) => item.video?.url || item.picture?.url)
    },
  },

  methods: {
    onMouseEnter(index) {
      if (this.isActive(index)) {
        this.activeVideo.pause()
        if (this.timerFct) clearInterval(this.timerFct)
      }
    },

    onMouseLeave(index) {
      if (this.isActive(index)) {
        this.activeVideo.play()
        this.startTimer()
      }
    },

    toNextVideo() {
      this.setActiveIndex((this.activeIndex + 1) % this.mediaItems.length)
    },

    sectionOnScreen(onScreen) {
      if (onScreen && !this.hasPlayedOnce) {
        this.setActiveIndex(0)
        this.hasPlayedOnce = true
      }
    },

    startTimer() {
      if (this.timerFct) clearInterval(this.timerFct)

      this.timerFct = setInterval(() => {
        this.activeVideoProgression += 0.2

        if (this.activeVideoProgression > this.itemDuration) {
          this.activeVideoProgression = 0
          clearInterval(this.timerFct)
          this.toNextVideo()
        }

        this.onVideoProgress()
      }, 200)
    },

    onVideoProgress() {
      const dataProg = { ...this.prog }
      dataProg[this.activeIndex] =
        (this.activeVideoProgression * 100) / this.itemDuration
      this.progressions = dataProg
    },

    resetVideoTimers() {
      this.activeVideo.currentTime = 0
      this.activeVideoProgression = 0
      this.onVideoProgress()
    },

    setActiveIndex(index) {
      if (index >= this.mediaItems.length) {
        index = this.mediaItems.length - 1
      }

      this.activeVideo.pause()
      this.resetVideoTimers()
      this.activeIndex = index
      this.startTimer()
      this.activeVideo.play()
    },

    isActive(index) {
      if (
        this.activeIndex >= this.mediaItems.length - 1 &&
        index > this.activeIndex
      )
        return true

      return index === this.activeIndex
    },

    getProgress(index) {
      if (!this.isActive(index)) return 0
      return this.progressions[index]
    },

    getProgressSpeed(index) {
      if (!this.isActive(index)) return 1000
      return 200
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 80px 0;
  @include below(large) {
    margin: 80px 10vw;
  }
  @include below(small) {
    margin: 80px 0;
  }
}

.inner {
  display: flex;
  align-items: stretch;
  justify-content: center;
  max-width: 1440px;

  background-color: #fff4ee;

  @include below(large) {
    flex-direction: column-reverse;
  }
}

.title {
  margin: 0 0 0 10px;

  font-weight: 200;
  @include below(small) {
    font-size: 32px;
    text-align: center;
  }
}
.description {
  @include typo-body;
  margin: 0 auto;

  color: $gray-700;
}
.desc {
  display: inline-block;
  width: 50%;
  padding: 70px 60px;
  @include below(large) {
    width: 100%;
  }
  @include below(small) {
    padding: 32px 20px;
  }
}
.view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 800px;

  background-color: #122182;
  background-image: url('@/assets/images/slice/scrollable-back.png');
  background-repeat: no-repeat;
  background-position: 65% 10%;
  background-size: auto 115%;

  @include below(large) {
    width: 100%;
    height: 600px;
  }
  @include below(small) {
    height: 400px;
  }
}

.mediaContainer {
  width: 360px;
  @include below(large) {
    width: 280px;
  }
  @include below(small) {
    width: 160px;
  }
}
.itemsContainer {
  margin-top: 40px;
}

.item {
  display: flex;
  align-items: center;

  color: $gray-400;
  text-align: left;

  cursor: pointer;

  transition-duration: 0.3s;
  transition-property: color, background-colors;

  &:not(:first-of-type) {
    margin-top: 52px;
  }
}
.itemDescription {
  h3 {
    @include typo-headline-bold;
    margin-bottom: 4px;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  border-radius: 48px;
  @include below(small) {
    margin-right: 12px;
    border-radius: 24px;
  }
}

.circleProgress circle:last-child {
  stroke: $gray-400 !important;
}

.itemActive .circleProgress circle:last-child {
  stroke: $bluko-500 !important;
}

.itemIcon {
  @include typo-title;
  width: 48px;
  height: 48px;
  border-radius: 50%;

  line-height: 48px;
  text-align: center;

  background-color: white;
  opacity: 0.5;

  transition-duration: 0.3s;
  transition-property: opacity, background-color;

  &.itemActive {
    color: $bluko-500;

    background-color: white;
    opacity: 1;
  }
}

.itemActive {
  color: $gray-1000;
}

.hide {
  opacity: 0 !important;
}
</style>
