<template>
  <article
    :class="[
      [$style.container],
      { [$style.isBig]: isBig },
      { [$style.isSmall]: isSmall },
      { [$style.isHorizontal]: isHorizontal },
    ]"
    data-luko-tracking="ArticleThumbnail"
  >
    <div :class="$style.articleCard">
      <a :href="content.articleLink" :class="$style.thumbnailContainer">
        <PImage
          :class="$style.thumbnail"
          :image-object="content.image"
          sizes="xs:100vw"
          :quality="80"
        />
      </a>
      <div :class="$style.content">
        <a :href="content.articleLink" :class="$style.articleLink">
          <h3 :class="$style.title">
            {{ content.title[0].text }}
          </h3>
        </a>
        <RichText
          :class="$style.description"
          :text-params="content.description"
        />

        <div :class="$style.infos">
          <PImage :image-object="content.author_picture" />
          <p :class="$style.text">
            {{ $t('by') }}
            <span :class="$style.authorName">
              {{ content.author_name }}
            </span>
            <span v-if="content.category.uid" :class="$style.category">
              {{ $t('in') }}
              <a :href="content.categoryLink">
                {{ categoryName }}
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { getFormatedLink } from '~/mixins/getFormatedLink'
import RichText from '~/components/RichText'
import PImage from '~/components/PImage'

export default {
  name: 'ArticleThumbnail',

  components: {
    RichText,
    PImage,
  },

  mixins: [getFormatedLink],

  props: {
    article: {
      type: Object,
      required: true,
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    isBig: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    categoryName() {
      const categoryId = this.article.data.category_page.id
      return this.$store.state.adviceCategoryData[categoryId].category_name
    },

    content() {
      return {
        image: this.article.data.hero_image,
        title: this.article.data.hero_title,
        description: this.article.data.hero_description,
        author_picture: this.article.data.author_picture,
        author_name: this.article.data.author_name,
        category: this.article.data.category_page,
        categoryLink: this.getFormatedLink({
          link_type: 'Document',
          uid: this.article.data.category_page?.uid,
          type: 'advices_category_page',
          lang: this.$i18n.localeProperties.prismicIso.toLowerCase(),
        }),
        articleLink: this.getFormatedLink({
          link_type: 'Document',
          uid: this.article.uid,
          type: 'advice_page',
          lang: this.article.lang,
        }),
      }
    },
  },
}
</script>

<style lang="scss" module>
.container.isHorizontal .articleCard {
  flex-direction: row;
  align-items: center;

  .thumbnailContainer {
    flex-shrink: 0;
    width: 100%;
    @include above(large) {
      max-width: 253px;
    }
  }

  .thumbnail {
    height: 192px;
  }

  .content {
    margin-top: 0;
  }
}

.container.isSmall .articleCard {
  flex-direction: row;
  align-items: center;
  .thumbnailContainer {
    flex-shrink: 0;
    width: 100%;
    max-width: 253px;
  }
  .thumbnail {
    height: 192px;
  }
}

.container.isBig .articleCard {
  height: 100%;
  .title {
    @include typo-large-title;
  }

  .description {
    @include above(small) {
      -webkit-line-clamp: 1;
    }
  }

  .thumbnailContainer {
    flex-grow: 1;
    width: 100%;
  }

  .thumbnail {
    min-height: 256px;

    @include above(large) {
      height: 100%;
      min-height: 448px;
    }
  }
}

.container .articleCard {
  display: flex;
  flex-direction: column;

  color: inherit;
  text-decoration: none;

  &:hover {
    .title {
      text-decoration: underline;
    }
  }
}

.articleLink {
  color: black;
  text-decoration: none;
}
.thumbnailContainer {
  margin-right: 16px;
}
.thumbnail {
  width: 100%;
  height: 192px;
  border-radius: 8px;
  object-fit: cover;

  @include above(large) {
    height: 256px;
  }
}

.content {
  margin-top: 16px;
}

.title {
  @include typo-headline;

  font-weight: 500;
}

.description {
  display: none;

  @include above(small) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    margin-top: 8px;
    overflow: hidden;

    color: $gray-700;
  }
}

.infos {
  display: flex;
  align-items: center;
  margin-top: 16px;

  img {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    object-fit: contain;
  }
}

.text {
  margin-left: 8px;

  a {
    display: inline;

    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.category {
  display: none;

  @include above(small) {
    display: inline;
  }
}
</style>
