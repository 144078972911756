var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.sectionOnScreen,
    intersection: {
      threshold: 1,
    },
  }),expression:"{\n    callback: sectionOnScreen,\n    intersection: {\n      threshold: 1,\n    },\n  }"}],class:_vm.$style.container},[_c('Wrapper',{class:_vm.$style.inner},[_c('Title',{class:_vm.$style.title,attrs:{"title-params":_vm.primary.title,"is-extra-title":""}}),_vm._v(" "),_c('div',{class:_vm.$style.content},[_c('div',{class:_vm.$style.view},[_vm._l((_vm.items),function(ref,videoIndex){
  var video = ref.video;
return [_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive(videoIndex)),expression:"isActive(videoIndex)"}],key:videoIndex,ref:'video-' + videoIndex,refInFor:true,class:[_vm.$style.videoContainer],attrs:{"muted":"muted"},domProps:{"muted":true}},[_c('source',{attrs:{"src":video.url,"type":"video/mp4"}})])]})],2),_vm._v(" "),_c('div',{class:_vm.$style.desc},_vm._l((_vm.items),function(item,itemIndex){
  var _obj, _obj$1;
return _c('div',{key:itemIndex,class:[_vm.$style.item, ( _obj = {}, _obj[_vm.$style.itemActive] = _vm.isActive(itemIndex), _obj )],on:{"click":function($event){return _vm.setActiveIndex(itemIndex)},"mouseenter":function($event){return _vm.onMouseEnter(itemIndex)},"mouseleave":function($event){return _vm.onMouseLeave(itemIndex)}}},[_c('div',{class:_vm.$style.iconContainer},[_c('RadialProgressBar',{class:_vm.$style.circleProgress,staticStyle:{"position":"absolute","z-index":"10"},attrs:{"completed-steps":_vm.getProgress(itemIndex),"animate-speed":_vm.getProgressSpeed(itemIndex),"diameter":64,"total-steps":100,"inner-stroke-color":"transparent","stroke-width":4}}),_vm._v(" "),_c('PImage',{class:[
                _vm.$style.itemIcon,
                ( _obj$1 = {}, _obj$1[_vm.$style.itemActive] = _vm.isActive(itemIndex), _obj$1 ) ],attrs:{"image-object":item.icon}})],1),_vm._v(" "),_c('div',{class:_vm.$style.infos},[_c('Title',{class:_vm.$style.itemTitle,attrs:{"title-params":item.title}}),_vm._v(" "),_c('RichText',{attrs:{"text-params":item.description}})],1)])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }