<template>
  <div :class="$style.container" data-luko-tracking="IconListSlice">
    <RichText
      :class="$style.richtext"
      :text-params="slice.primary.introduction"
    />
    <div v-for="(item, index) in slice.items" :key="index" :class="$style.item">
      <img :src="item.icon.url" />
      <RichText :class="$style.richtext" :text-params="item.description" />
    </div>
  </div>
</template>

<script>
import RichText from '~/components/RichText'

export default {
  name: 'IconListSlice',
  components: {
    RichText,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  max-width: 840px;
}

.item {
  display: flex;
  align-items: center;

  margin-bottom: 24px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 25px;
  }
}

.richtext {
  h1 {
    margin: 64px 0 24px 0;

    color: $gray-1000;
    font-weight: 500;
    font-size: 32px;
    font-style: normal;
    line-height: 48px;
  }

  p {
    color: $gray-700;
    font-weight: normal;

    font-size: 16px;
    line-height: 24px;
  }

  a {
    color: $gray-1000;
  }

  ul {
    display: block;
    margin-left: 40px;

    list-style: outside;

    list-style-type: disc;
    li {
      display: list-item;
      margin: 8px 0;

      color: $gray-1000;
      font-size: 16px;
      line-height: 24px;

      text-align: -webkit-match-parent;
    }
  }
}
</style>
