<template>
  <section :class="$style.container" data-luko-tracking="BundleProduct">
    <Wrapper :class="$style.inner">
      <div :class="$style.header">
        <Title
          :class="$style.title"
          :title-params="content.title"
          is-large-title
        />
        <LkButton
          is-small
          is-secondary
          :href="formatedButtonLink(content.link)"
        >
          {{ content.link_text }}
        </LkButton>
      </div>
      <ul :class="$style.list">
        <li
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          :class="$style.item"
          data-aos="fade-up"
          :data-aos-delay="(itemIndex % 4) * 100"
          data-aos-duration="800"
        >
          <div :class="$style.imageContainer">
            <PImage
              :image-object="item.image"
              :class="$style.itemImage"
              sizes="xs:100vw sm:240px"
              loading="lazy"
            />
          </div>
          <div :class="$style.content">
            <Title :title-params="item.title" :class="$style.itemTitle" />
            <RichText :text-params="item.text" :class="$style.itemText" />
            <PLink
              is-highlight
              :link-object="item.link"
              color="bluko"
              :class="$style.itemLink"
              >{{ item.link_text }}</PLink
            >
          </div>
        </li>
      </ul>
    </Wrapper>
  </section>
</template>

<script>
import Title from '~/components/Title'
import PImage from '~/components/PImage'
import PLink from '~/components/PLink'
import RichText from '~/components/RichText'
import linkResolver from '~/utils/prismic/link-resolver'

export default {
  name: 'BundleProduct',

  components: {
    Title,
    PImage,
    PLink,
    RichText,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },
  },

  methods: {
    formatedButtonLink(link) {
      return linkResolver(link)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 128px 0;
  padding: 0 24px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include below(medium) {
    flex-direction: column;
  }
}

.title {
  @include below(medium) {
    text-align: center;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px;
  margin-top: 24px;

  @include above(medium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.item {
  display: flex;
  gap: 15px;

  @include below(xsmall) {
    flex-direction: column;

    text-align: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.itemTitle {
  @include typo-headline-bold;

  @include below(xsmall) {
    margin: 0 auto;
  }
}

.itemText {
  margin-top: 8px;

  color: $gray-700;
}

.itemLink {
  margin-top: 16px;

  @include below(xsmall) {
    margin: 16px auto 0;
  }
}

.imageContainer {
  @include below(xsmall) {
    display: flex;
    justify-content: center;
  }
}

.itemImage {
  width: 180px;
  height: 180px;

  border-radius: 8px;
  object-fit: cover;

  @include below(xsmall) {
    width: 100%;
    height: 240px;
  }
}
</style>
