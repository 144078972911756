<template>
  <div :class="$style.container" data-luko-tracking="SensorListSlice">
    <div v-for="(item, index) in slice.items" :key="index" :class="$style.item">
      <img :src="item.icon.url" />
      <RichText :class="$style.richtext" :text-params="item.description" />
    </div>
  </div>
</template>

<script>
import RichText from '~/components/RichText'

export default {
  name: 'SensorListSlice',
  components: {
    RichText,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  justify-content: space-between;
  max-width: 840px;
  margin-top: 40px;
  @include below(small) {
    flex-direction: column;
    align-items: center;
  }
}

.item {
  display: flex;
  flex-direction: column;

  max-width: 254px;

  @include below(small) {
    justify-content: center;

    text-align: center;

    img {
      margin: 0 auto;
    }
  }

  img {
    width: 40px;
    height: 40px;
  }
}

.richtext {
  h1 {
    margin: 12px 0 1px 0;

    color: black;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }

  p {
    margin: 0 0 56px 0;

    color: $gray-700;
    font-weight: normal;

    font-size: 16px;
    line-height: 24px;
  }

  a {
    color: $gray-1000;
  }

  ul {
    display: block;
    margin-left: 40px;

    list-style: outside;

    list-style-type: disc;
    li {
      display: list-item;
      margin: 8px 0;

      color: $gray-1000;
      font-size: 16px;
      line-height: 24px;

      text-align: -webkit-match-parent;
    }
  }
}
</style>
