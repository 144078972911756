// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VimeoSection__container___4iHz8{display:flex;align-items:center;justify-content:center;margin:128px 0;padding:0 24px;background:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "VimeoSection__container___4iHz8"
};
module.exports = ___CSS_LOADER_EXPORT___;
