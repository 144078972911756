<template>
  <section :class="$style.container" data-luko-tracking="AlternatingBlocks">
    <Title :title-params="primary.title" />
    <Title :title-params="primary.subtitle" :class="$style.subtitle" />
    <article
      v-for="(item, index) in items"
      :key="index"
      :class="{ reverse: item.image_position }"
    >
      <figure>
        <img :src="item.image.url" :class="{ mobile_hidden: item.video }" />
      </figure>
      <caption>
        <Title :title-params="item.title" />
        <RichText :text-params="item.description" />
        <span v-if="item.cta_title">
          <a :href="item.cta_url.url" class="arrow_link">
            {{ item.cta_title }}
          </a>
        </span>
      </caption>
      <video
        v-if="item.video"
        :class="{ mobile_hidden: item.video }"
        loop
        autoplay="true"
      >
        <source :src="item.video" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </article>
  </section>
</template>

<script>
import RichText from '~/components/RichText'
import Title from '~/components/Title'

export default {
  name: 'AlternatingBlocks',
  components: {
    RichText,
    Title,
  },
  props: {
    slice: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 160px 0;
  padding: 0 24px;

  background: #ffffff;

  h1 {
    max-width: 740px;
    margin: 0;

    color: $gray-1000;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  .subtitle h1 {
    color: $mint-500;
  }

  article {
    position: relative;

    display: flex;
    align-items: center;

    video {
      position: absolute;
      top: 93px;
      right: 99px;

      width: 260px;
    }

    figure {
      padding: 32px;
      img {
        position: relative;

        max-width: 455px;
      }
    }
    caption {
      max-width: 483px;
      margin-left: 55px;

      text-align: left;
      h1 {
        margin-bottom: 16px;

        color: $gray-1000;
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        text-align: left;
      }

      p {
        margin-bottom: 8px;

        color: $gray-700;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }

      strong {
        color: $gray-1000;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      span {
        display: block;
        margin-top: 40px;
      }
      span,
      a {
        color: $gray-1000;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
      a {
        text-decoration-line: underline;
      }
    }
  }

  @include below(medium) {
    margin: 80px 0;
    padding: 0 24px;
    article {
      flex-direction: column;
      margin-bottom: 140px;
      &:last-child {
        margin: 0;
      }
      video {
        display: none;
      }
      figure {
        padding: 24px;
        img {
          max-width: 100%;
        }
      }
      caption {
        max-width: initial;
        margin: 0;
        h1 {
          font-size: 28px;
          line-height: 40px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
article.reverse {
  flex-direction: row-reverse;

  @include below(medium) {
    flex-direction: column;
  }
}

.mobile_hidden {
  @include below(medium) {
    display: none;
  }
}
</style>
