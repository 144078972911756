<template>
  <section :class="$style.container" data-luko-tracking="VimeoSection">
    <iframe
      :src="content.vimeo_url.url"
      width="694"
      height="390"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
  </section>
</template>

<script>
export default {
  name: 'VimeoSection',

  components: {},

  props: {
    slice: {
      type: Object,
      default: null,
    },
  },

  computed: {
    content() {
      return this.slice.primary
    },
    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 128px 0;
  padding: 0 24px;

  background: #ffffff;
}
</style>
