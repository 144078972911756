<template>
  <section
    v-observe-visibility="{
      callback: sectionOnScreen,
      intersection: {
        threshold: 1,
      },
    }"
    :class="$style.container"
  >
    <Wrapper :class="$style.inner">
      <Title
        :class="$style.title"
        :title-params="primary.title"
        is-extra-title
      />
      <div :class="$style.content">
        <div :class="$style.view">
          <template v-for="({ video }, videoIndex) in items">
            <video
              v-show="isActive(videoIndex)"
              :key="videoIndex"
              :ref="'video-' + videoIndex"
              :class="[$style.videoContainer]"
              muted="muted"
            >
              <source :src="video.url" type="video/mp4" />
            </video>
          </template>
        </div>

        <div :class="$style.desc">
          <div
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
            :class="[$style.item, { [$style.itemActive]: isActive(itemIndex) }]"
            @click="setActiveIndex(itemIndex)"
            @mouseenter="onMouseEnter(itemIndex)"
            @mouseleave="onMouseLeave(itemIndex)"
          >
            <div :class="$style.iconContainer">
              <RadialProgressBar
                :class="$style.circleProgress"
                :completed-steps="getProgress(itemIndex)"
                :animate-speed="getProgressSpeed(itemIndex)"
                :diameter="64"
                :total-steps="100"
                inner-stroke-color="transparent"
                style="position: absolute; z-index: 10"
                :stroke-width="4"
              />
              <PImage
                :image-object="item.icon"
                :class="[
                  $style.itemIcon,
                  { [$style.itemActive]: isActive(itemIndex) },
                ]"
              />
            </div>
            <div :class="$style.infos">
              <Title :title-params="item.title" :class="$style.itemTitle" />
              <RichText :text-params="item.description" />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress/src/RadialProgressBar.vue'

import Title from '~/components/Title'
import RichText from '~/components/RichText'

export default {
  name: 'HomeVideoCarrousel',

  components: {
    Title,
    RichText,
    RadialProgressBar,
  },

  props: {
    primary: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      hasPlayedOnce: false,
      activeIndex: 0,
      prog: {},
      timeoutFct: null,
      activeVideoProgression: 0,
      timerFct: null,
    }
  },

  computed: {
    activeVideo() {
      if (!this.$refs['video-' + this.activeIndex]) return
      return this.$refs['video-' + this.activeIndex][0]
    },

    progressions: {
      get() {
        return this.prog
      },
      set(newValue) {
        this.prog = newValue
      },
    },

    itemDuration() {
      return Math.round(this.activeVideo.duration + 4)
    },
  },

  methods: {
    isUnderMediumScreen: () => window.matchMedia('(max-width: 960px)').matches,

    onMouseEnter(index) {
      if (this.isActive(index)) {
        this.activeVideo.pause()
        if (this.timerFct) clearInterval(this.timerFct)
      }
    },

    onMouseLeave(index) {
      if (this.isActive(index)) {
        this.activeVideo.play()
        this.startTimer()
      }
    },

    toNextVideo() {
      this.setActiveIndex((this.activeIndex + 1) % this.items.length)
    },

    sectionOnScreen(onScreen) {
      if (onScreen && !this.hasPlayedOnce) {
        this.setActiveIndex(0)
        this.hasPlayedOnce = true
      }
    },

    startTimer() {
      if (this.timerFct) clearInterval(this.timerFct)

      this.timerFct = setInterval(() => {
        if (this.isUnderMediumScreen()) return
        this.activeVideoProgression += 0.2

        if (this.activeVideoProgression > this.itemDuration) {
          this.activeVideoProgression = 0
          clearInterval(this.timerFct)
          this.toNextVideo()
        }

        this.onVideoProgress()
      }, 200)
    },

    onVideoProgress() {
      const dataProg = { ...this.prog }
      dataProg[this.activeIndex] =
        (this.activeVideoProgression * 100) / this.itemDuration
      this.progressions = dataProg
    },

    resetVideoTimers() {
      this.activeVideo.currentTime = 0
      this.activeVideoProgression = 0
      this.onVideoProgress()
    },

    setActiveIndex(index) {
      this.activeVideo.pause()
      this.resetVideoTimers()
      this.activeIndex = index
      this.startTimer()
      this.activeVideo.play()
    },

    isActive(index) {
      return index === this.activeIndex
    },

    getProgress(index) {
      if (!this.isActive(index)) return 0
      return this.progressions[index]
    },

    getProgressSpeed(index) {
      if (!this.isActive(index)) return 1000
      return 200
    },
  },
}
</script>

<style lang="scss" module>
.container {
  margin: 80px 0;
}

.inner {
  padding: 20px 24px;
}

.content {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
}

.title {
  max-width: 640px;
  margin: 0 auto;

  text-align: center;

  h2 {
    margin-bottom: 28px;
  }
}

.view {
  display: none;

  @include above(medium) {
    display: block;
    max-width: 473px;
    padding-right: 73px;

    video {
      width: 100%;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 473px;

  text-align: center;

  transition-duration: 0.3s;
  transition-property: color, background-colors;

  &:not(:first-child) {
    margin-top: 64px;
  }

  @include above(small) {
    flex-direction: row;

    text-align: left;
  }

  @include above(medium) {
    color: $gray-400;

    cursor: pointer;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  padding: 12px;
  border-radius: 48px;

  background-color: $bluko-50;

  @include above(medium) {
    background-color: $gray-50;
  }
}

.circleProgress {
  display: none;
  @include above(medium) {
    display: block;
  }
}

.circleProgress circle:last-child {
  stroke: $gray-400 !important;
}

.itemActive .circleProgress circle:last-child {
  stroke: $bluko-500 !important;
}

.itemIcon {
  @include above(medium) {
    opacity: 0.5;
    filter: grayscale(100%);

    transition-duration: 0.3s;
    transition-property: opacity, filter;

    &.itemActive {
      opacity: 1;
      filter: grayscale(0);
    }
  }
}

.itemTitle {
  @include typo-headline;

  font-weight: 500;

  h3 {
    margin-bottom: 5px;
  }
}

.itemActive {
  color: $gray-1000;

  .iconContainer {
    background-color: $bluko-50;
  }
}
</style>
