// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeroFullImage__container___1I3BP{min-height:480px}@media (max-width:719px){.HeroFullImage__container___1I3BP{height:auto}}.HeroFullImage__inner___2Q-cy{max-width:1440px;height:100%;min-height:480px;background-repeat:no-repeat;background-position:50%;background-size:cover}@media (max-width:719px){.HeroFullImage__inner___2Q-cy{min-height:480px;background-size:cover}}.HeroFullImage__content___2ySmR{display:flex;flex-direction:column;justify-content:center;max-width:1000px;height:100%;padding:68px 120px}@media (max-width:719px){.HeroFullImage__content___2ySmR{padding:32px 64px}}.HeroFullImage__backgroundShadow___1vBfV{background:linear-gradient(90deg,rgba(0,0,0,.8),transparent)}.HeroFullImage__title___2WV73{color:inherit;font-weight:500;font-size:48px;line-height:64px;width:85%;color:#fff}@media (max-width:719px){.HeroFullImage__title___2WV73{color:inherit;font-weight:500;font-size:32px;line-height:48px;width:100%;color:#fff}}.HeroFullImage__description___16ovD{color:inherit;font-weight:400;font-size:20px;line-height:32px;width:85%;margin-top:16px;color:#fff}@media (max-width:719px){.HeroFullImage__description___16ovD{color:inherit;font-weight:400;font-size:16px;line-height:24px;width:100%;color:#fff}}.HeroFullImage__ctaSection___WQ2D9{margin-top:58px}@media (max-width:719px){.HeroFullImage__cta___Nt2eT{width:100%}}.HeroFullImage__trustpilot___28nv7{margin-top:62px}.HeroFullImage__trustpilotContent___1DXzo{display:flex;align-items:center}.HeroFullImage__trustpilotText___3-Di1{margin-left:8px;color:#fff}.HeroFullImage__link___2ApfN{text-decoration:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "HeroFullImage__container___1I3BP",
	"inner": "HeroFullImage__inner___2Q-cy",
	"content": "HeroFullImage__content___2ySmR",
	"backgroundShadow": "HeroFullImage__backgroundShadow___1vBfV",
	"title": "HeroFullImage__title___2WV73",
	"description": "HeroFullImage__description___16ovD",
	"ctaSection": "HeroFullImage__ctaSection___WQ2D9",
	"cta": "HeroFullImage__cta___Nt2eT",
	"trustpilot": "HeroFullImage__trustpilot___28nv7",
	"trustpilotContent": "HeroFullImage__trustpilotContent___1DXzo",
	"trustpilotText": "HeroFullImage__trustpilotText___3-Di1",
	"link": "HeroFullImage__link___2ApfN"
};
module.exports = ___CSS_LOADER_EXPORT___;
