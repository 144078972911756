// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconListSlice__container___1jYPj{display:flex;flex-direction:column;max-width:840px}.IconListSlice__item___a7TOK{display:flex;align-items:center;margin-bottom:24px}.IconListSlice__item___a7TOK img{width:30px;height:30px;margin-right:25px}.IconListSlice__richtext___3Afe6 h1{margin:64px 0 24px;color:#2c2302;font-weight:500;font-size:32px;font-style:normal;line-height:48px}.IconListSlice__richtext___3Afe6 p{color:#6b6651;font-weight:400;font-size:16px;line-height:24px}.IconListSlice__richtext___3Afe6 a{color:#2c2302}.IconListSlice__richtext___3Afe6 ul{display:block;margin-left:40px;list-style:outside;list-style-type:disc}.IconListSlice__richtext___3Afe6 ul li{display:list-item;margin:8px 0;color:#2c2302;font-size:16px;line-height:24px;text-align:-webkit-match-parent}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "IconListSlice__container___1jYPj",
	"item": "IconListSlice__item___a7TOK",
	"richtext": "IconListSlice__richtext___3Afe6"
};
module.exports = ___CSS_LOADER_EXPORT___;
