// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardSlice__container___6U0Mk{margin:128px 0 0;padding:0 24px}.CardSlice__inner___10z5m{display:grid;grid-gap:40px}@media (min-width:960px){.CardSlice__inner___10z5m{grid-template-columns:repeat(2,1fr)}}.CardSlice__card___3AYMp{padding:32px 24px;border-radius:8px;background-color:#fcf7e8}@media (min-width:720px){.CardSlice__card___3AYMp{padding:80px 73px}}.CardSlice__imageContainer___7ivQK{text-align:center}.CardSlice__icon___2FP11{width:56px;height:56px}.CardSlice__text___3Xm4D,.CardSlice__title___2Xvdu{margin-top:16px;text-align:center}.CardSlice__link___1Un0P{display:block;margin:24px auto 0;text-align:left}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "CardSlice__container___6U0Mk",
	"inner": "CardSlice__inner___10z5m",
	"card": "CardSlice__card___3AYMp",
	"imageContainer": "CardSlice__imageContainer___7ivQK",
	"icon": "CardSlice__icon___2FP11",
	"text": "CardSlice__text___3Xm4D",
	"title": "CardSlice__title___2Xvdu",
	"link": "CardSlice__link___1Un0P"
};
module.exports = ___CSS_LOADER_EXPORT___;
