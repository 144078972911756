<template>
  <section data-luko-tracking="ProcessArrowBlock" :class="$style.container">
    <Wrapper :class="$style.inner">
      <Title :title-params="content.title" is-extra-title />
      <RichText
        :text-params="content.description"
        :class="$style.description"
      />

      <div :class="$style.items">
        <template v-for="(item, itemIndex) of items">
          <div
            :key="itemIndex"
            :class="$style.item"
            data-aos="fade-up"
            :data-aos-delay="itemIndex * 150"
            data-aos-duration="800"
          >
            <PImage :image-object="item.icon" :class="$style.icon" />
            <Title :title-params="item.title" :class="$style.title" />
            <RichText
              :text-params="item.description"
              :class="$style.description"
            />
            <div v-if="item.cta_link && item.cta_text" :class="$style.link">
              <PLink
                :link-object="item.cta_link"
                is-highlight
                color="bluko"
                :targeted-section="item.targeted_section"
              >
                {{ item.cta_text }}
              </PLink>
            </div>
          </div>

          <ArrowRight
            v-if="itemIndex !== items.length - 1"
            :key="'arrow' + itemIndex"
            name="arrow-right"
            class="icon-gray-200"
            :class="$style.arrow"
            width="20px"
            height="20px"
          />
        </template>
      </div>

      <PLink
        v-if="content.cta_link && content.cta_link.url"
        :link-object="content.cta_link"
        :class="$style.cta_button"
      >
        <LkButton>{{ content.cta_text }}</LkButton>
      </PLink>
    </Wrapper>
  </section>
</template>

<script>
import ArrowRight from '@demain-es/lukomponents/src/icons/ArrowRight.vue'

import Title from '~/components/Title'
import RichText from '~/components/RichText'
import PLink from '~/components/PLink'

export default {
  name: 'ProcessArrowBlock',

  components: {
    Title,
    RichText,
    PLink,
    ArrowRight,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 128px 0 24px;
}

.inner {
  max-width: 1280px;

  text-align: center;
}
.description {
  p {
    @include typo-body;

    color: $gray-700;
  }
  h2,
  h3 {
    @include typo-extra-title;
  }
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;

  @include above(large) {
    flex-direction: row;
  }
}

.cta_button {
  display: none;

  text-decoration: none;

  @include above(large) {
    display: inline-block;
    margin-top: 48px;
  }
}

.item {
  max-width: 320px;
  min-height: 230px;
  margin: 24px;
  padding: 24px 32px;
  border: 1px solid $gray-100;
  border-radius: 8px;
}

.icon {
  margin-bottom: 24px;
}

.title {
  @include typo-headline-bold;
  margin: 0;
}

.link {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  a {
    max-width: 195px;

    text-align: left;
  }
}

.arrow {
  margin: 10px;

  transform: rotate(90deg);

  @include above(large) {
    transform: initial;
  }
}
</style>
