// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsletterForm__formContainer___22XHh{display:flex;flex-direction:column;grid-gap:8px;gap:8px}@media (min-width:540px){.NewsletterForm__formContainer___22XHh{flex-direction:row;align-items:center}}.NewsletterForm__input___3Gs7_{flex-grow:1;width:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"formContainer": "NewsletterForm__formContainer___22XHh",
	"input": "NewsletterForm__input___3Gs7_"
};
module.exports = ___CSS_LOADER_EXPORT___;
