// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Termination__container___C_4Br{display:flex;justify-content:center;margin:24px 0 126px}.Termination__inner___giNDl{width:100%;max-width:840px;margin:0 24px;padding:32px;border:1px solid #eae9e3;border-radius:8px}.Termination__content___3nZsV,.Termination__inner___giNDl{display:flex;flex-direction:column;align-items:center}.Termination__content___3nZsV{grid-gap:16px;gap:16px}@media (min-width:720px){.Termination__content___3nZsV{flex-direction:row}}.Termination__content___3nZsV p{color:inherit;font-weight:400;font-size:20px;line-height:32px;color:#6b6651;text-align:center}@media (min-width:720px){.Termination__content___3nZsV p{text-align:left}}.Termination__icon___1D9Ez{width:24px;height:24px}.Termination__button___1ZzuR{margin-top:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "Termination__container___C_4Br",
	"inner": "Termination__inner___giNDl",
	"content": "Termination__content___3nZsV",
	"icon": "Termination__icon___1D9Ez",
	"button": "Termination__button___1ZzuR"
};
module.exports = ___CSS_LOADER_EXPORT___;
