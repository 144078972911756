<template>
  <div :class="{ [$style.isDark]: isDark }" data-luko-tracking="CtaBox">
    <AddressForm
      v-if="type === 'Address'"
      :placeholder="placeholder"
      :button-text="text"
      :is-dark="isDark"
    />
    <NewsletterForm
      v-else-if="type === 'Email'"
      :placeholder="placeholder"
      :button-text="text"
      :success-text="successText"
    />
    <LkButton
      v-else-if="type === 'Button'"
      :class="$style.button"
      :href="ctaTarget"
    >
      {{ text }}
    </LkButton>
  </div>
</template>

<script>
import AddressForm from '~/components/AddressForm'
import { getFormatedLink } from '~/mixins/getFormatedLink'

import NewsletterForm from '../NewsletterForm'

export default {
  name: 'CtaBox',

  components: {
    AddressForm,
    NewsletterForm,
  },

  mixins: [getFormatedLink],

  props: {
    type: {
      type: String,
      required: true,
    },

    text: {
      type: String,
      default: '',
    },

    successText: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    link: {
      type: Object,
      default: () => ({}),
    },

    isDark: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ctaTarget() {
      return `${this.getFormatedLink(this.link)}${
        process.client && window.location.search ? window.location.search : ''
      }`
    },
  },
}
</script>

<style lang="scss" module>
.isDark {
  .emailField input {
    border-color: $bluko-600;

    color: white;

    background-color: $bluko-700;

    &::placeholder {
      color: white;
    }

    &:hover {
      border-color: $bluko-300;
    }

    &:focus {
      border-color: $bluko-300;

      box-shadow: 0 0 0 4px $bluko-500;
    }
  }
}

.button {
  @include below(small) {
    width: 100%;
  }
}
</style>
