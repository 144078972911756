<template>
  <section
    :class="$style.buttonContainer"
    :style="backgroundColor"
    data-luko-tracking="ButtonSlice"
  >
    <Wrapper :class="$style.inner">
      <Title
        v-if="primary.title"
        :title-params="primary.title"
        :class="$style.title"
      />
      <RichText
        v-if="primary.description"
        :text-params="primary.description"
        :class="$style.description"
      />
      <LkButton
        :class="$style.button"
        is-fluid-below-small
        :href="getFormatedLink(primary.cta_link)"
        :target="primary.cta_link.target"
        data-cta="calendly"
      >
        {{ primary.cta_text }}
      </LkButton>
    </Wrapper>
  </section>
</template>

<script>
import { getFormatedLink } from '~/mixins/getFormatedLink'
import Title from '~/components/Title'
import RichText from '~/components/RichText'

export default {
  name: 'ButtonSlice',
  components: {
    Title,
    RichText,
  },

  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      primary: this.slice.primary,
    }
  },

  computed: {
    backgroundColor() {
      return `background-color: ${this.primary.background_color}`
    },
  },
}
</script>

<style lang="scss" module>
.buttonContainer {
  padding: 64px 24px;

  text-align: center;
}
.inner {
  max-width: 694px;
}
.title {
  @include typo-extra-title;
  margin-bottom: 24px;
}
.description {
  @include typo-body;
  margin-bottom: 24px;

  color: $gray-700;

  strong {
    color: $gray-1000;
    font-weight: 500;
  }
}
</style>
