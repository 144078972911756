// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactSection__container___UtcZw{width:100%;background-color:#fffbf2}.ContactSection__inner___3Y6IA{flex-wrap:wrap;grid-gap:40px;gap:40px;max-width:1280px;min-height:512px;padding:40px 0}.ContactSection__box___2RBK2,.ContactSection__inner___3Y6IA{display:flex;align-items:center;justify-content:center}.ContactSection__box___2RBK2{width:100%;max-width:410px;border-radius:8px;text-align:center;background-color:#fff}@media (max-width:1279px){.ContactSection__box___2RBK2{margin:0 24px}}@media (min-width:720px){.ContactSection__box___2RBK2:first-child,.ContactSection__box___2RBK2:nth-child(3){width:480px;min-height:370px}}@media (min-width:1280px){.ContactSection__box___2RBK2:first-child,.ContactSection__box___2RBK2:nth-child(3){width:360px;min-height:312px}}.ContactSection__box___2RBK2:nth-child(3){order:2}@media (min-width:1280px){.ContactSection__box___2RBK2:nth-child(3){order:3}}.ContactSection__box___2RBK2:nth-child(2){order:3}.ContactSection__box___2RBK2:nth-child(2) .ContactSection__icon___1Q9MZ{width:135px;height:70px}@media (min-width:1280px){.ContactSection__box___2RBK2:nth-child(2){order:2;max-width:480px;min-height:384px}}.ContactSection__itemWrapper___1sjLU{padding:24px 32px}.ContactSection__description___ZDEfG h3{color:inherit;font-weight:500;font-size:20px;line-height:32px}.ContactSection__description___ZDEfG h3:first-of-type{margin-top:16px}.ContactSection__description___ZDEfG p{color:inherit;font-weight:400;font-size:16px;line-height:24px;margin-top:8px;color:#6b6651}.ContactSection__icon___1Q9MZ{width:48px;height:48px}.ContactSection__cta___Db9HK{margin-top:32px;text-decoration:none}.ContactSection__hide___1xiqP{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "ContactSection__container___UtcZw",
	"inner": "ContactSection__inner___3Y6IA",
	"box": "ContactSection__box___2RBK2",
	"icon": "ContactSection__icon___1Q9MZ",
	"itemWrapper": "ContactSection__itemWrapper___1sjLU",
	"description": "ContactSection__description___ZDEfG",
	"cta": "ContactSection__cta___Db9HK",
	"hide": "ContactSection__hide___1xiqP"
};
module.exports = ___CSS_LOADER_EXPORT___;
