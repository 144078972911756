<template>
  <section :class="$style.container" data-luko-tracking="SimpleSlice">
    <Wrapper :class="$style.inner">
      <Title
        v-if="content.title"
        :class="$style.contentTitle"
        is-large-title
        :title-params="content.title"
      />
      <div
        :class="[
          [$style.content],
          { [$style.isReverse]: content.image_position },
        ]"
      >
        <div :class="$style.view">
          <PImage
            loading="lazy"
            sizes="xs:600px"
            :quality="80"
            :image-object="content.image"
          />
        </div>
        <div :class="$style.desc">
          <Title
            :title-params="content.subtitle"
            :class="$style.contentSubtitle"
          />
          <RichText
            :text-params="content.text"
            :class="$style.contentDescription"
          />
          <PLink :link-object="content.link_url" is-highlight color="bluko">
            {{ content.link_text }}
          </PLink>
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import RichText from '~/components/RichText'
import Title from '~/components/Title'
import PImage from '~/components/PImage'
import PLink from '~/components/PLink'

export default {
  name: 'SimpleSection',

  components: {
    RichText,
    Title,
    PImage,
    PLink,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      return this.slice.primary
    },

    items() {
      return this.slice.items
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 0 24px;
}

.inner {
  margin-top: 128px;
  margin-bottom: 128px;
}

.content {
  margin-top: 32px;

  @include above(small) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    column-gap: 24px;

    &.isReverse {
      flex-direction: row-reverse;
    }
  }

  @include above(small) {
    column-gap: 32px;
  }
  @include above(large) {
    column-gap: 40px;
  }
}

.desc {
  flex-basis: 0;
  flex-grow: 1;

  a {
    margin-top: 40px;
  }

  @include above(small) {
    margin-top: 0;
  }
  @include above(medium) {
    padding: 0 73px;
  }
}

.contentTitle {
  text-align: center;
}

.contentSubtitle {
  @include typo-large-title;
  p {
    margin-bottom: 10px;

    color: $gray-1000;
    line-height: 48px;
  }
}
.contentDescription {
  margin-top: 16px;

  color: $gray-700;
}

.view {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 620px;

  img {
    display: block;
    width: 100%;
  }

  @include above(medium) {
    flex-basis: 15%;
  }
}
</style>
