var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.$style.container,attrs:{"data-luko-tracking":"MediaCarrousel"}},[_c('Wrapper',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.sectionOnScreen,
      intersection: {
        threshold: 1,
      },
    }),expression:"{\n      callback: sectionOnScreen,\n      intersection: {\n        threshold: 1,\n      },\n    }"}],class:_vm.$style.inner},[_c('div',{class:_vm.$style.desc},[_c('Title',{class:_vm.$style.title,attrs:{"title-params":_vm.primary.title,"is-extra-title":""}}),_vm._v(" "),_c('RichText',{class:_vm.$style.description,attrs:{"text-params":_vm.primary.description}}),_vm._v(" "),_c('div',{class:_vm.$style.itemsContainer},_vm._l((_vm.items),function(item,itemIndex){
    var _obj, _obj$1, _obj$2;
return _c('div',{key:itemIndex,class:[_vm.$style.item, ( _obj = {}, _obj[_vm.$style.itemActive] = _vm.isActive(itemIndex), _obj )],on:{"click":function($event){return _vm.setActiveIndex(itemIndex)},"mouseenter":function($event){return _vm.onMouseEnter(itemIndex)},"mouseleave":function($event){return _vm.onMouseLeave(itemIndex)}}},[_c('div',{class:_vm.$style.iconContainer},[_c('RadialProgressBar',{class:_vm.$style.circleProgress,attrs:{"completed-steps":_vm.getProgress(itemIndex),"animate-speed":_vm.getProgressSpeed(itemIndex),"diameter":72,"total-steps":100,"inner-stroke-color":"transparent","stroke-width":4}},[_c('div',{class:[
                  _vm.$style.itemIcon,
                  ( _obj$1 = {}, _obj$1[_vm.$style.itemActive] = _vm.isActive(itemIndex), _obj$1 ),
                  ( _obj$2 = {}, _obj$2[_vm.$style.hide] = itemIndex >= _vm.mediaItems.length, _obj$2 ) ]},[_vm._v("\n                "+_vm._s(itemIndex + 1)+"\n              ")])])],1),_vm._v(" "),_c('div',{class:_vm.$style.infos},[_c('RichText',{class:_vm.$style.itemDescription,attrs:{"text-params":item.description}})],1)])}),0)],1),_vm._v(" "),_c('div',{class:_vm.$style.view},[_vm._l((_vm.items),function(item,itemIndex){return [(item.video && item.video.url)?[_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive(itemIndex)),expression:"isActive(itemIndex)"}],key:'video-' + itemIndex,ref:'video-' + itemIndex,refInFor:true,class:[_vm.$style.mediaContainer],attrs:{"muted":"muted"},domProps:{"muted":true}},[_c('source',{attrs:{"src":item.video.url,"type":"video/mp4"}})])]:_vm._e(),_vm._v(" "),(item.picture && item.picture.url)?[_c('PImage',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive(itemIndex)),expression:"isActive(itemIndex)"}],key:'image-' + itemIndex,ref:'image-' + itemIndex,refInFor:true,class:[_vm.$style.mediaContainer],attrs:{"image-object":item.picture}})]:_vm._e()]})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }