<template>
  <section
    id="newsletterSection"
    :class="$style.container"
    :style="backgroundImage"
  >
    <Wrapper :class="[$style.inner, { [$style.hasBackground]: hasBackground }]">
      <Title
        :title-params="primary.title"
        :class="$style.title"
        is-large-title
      />
      <CtaBox
        v-if="primary.cta_type !== 'None'"
        :placeholder="primary.input_placeholder"
        :type="primary.cta_type"
        :text="primary.cta_text"
        :success-text="primary.cta_text_success"
        :link="primary.cta_link"
        :is-dark="primary.is_dark"
        :class="$style.ctaBox"
      />
    </Wrapper>
  </section>
</template>

<script>
import Title from '~/components/Title'

import CtaBox from '../CtaBox'

export default {
  name: 'NewsletterSection',

  components: {
    Title,
    CtaBox,
  },

  props: {
    primary: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    hasBackground() {
      return !!this.primary.background_image.url
    },
    backgroundImage() {
      if (!this.hasBackground) return
      return `background-image: url(${this.primary.background_image.url})`
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 0 24px;

  background-color: $mint-50;
  background-position: bottom;
  background-size: cover;
}

.inner {
  max-width: 620px;
  margin: 0 auto;
  padding-bottom: 80px;

  text-align: center;
}

.hasBackground {
  padding-bottom: 500px;
}

.title {
  text-align: center;
}

.newsletterInput {
  margin-top: 24px;
}

.ctaBox {
  margin: 56px auto 0;
}
</style>
