// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BottomBanner__container___19REb{position:relative;padding:0 24px;overflow:hidden;background-color:#fcf7e8;background-repeat:no-repeat;background-position:bottom;background-size:contain}@media (min-width:960px){.BottomBanner__container___19REb{min-height:330px;padding:0}}@media (max-width:959px){.BottomBanner__container___19REb .BottomBanner__right_image___1nOhZ{top:auto;bottom:-80px;display:block;height:315px}}.BottomBanner__content___23m-K{position:relative;z-index:1;display:flex;flex-direction:column;align-items:center;justify-content:center;padding:32px 0 260px}@media (min-width:720px){.BottomBanner__content___23m-K{padding:76px 0}}.BottomBanner__title___nyksF{max-width:702px;text-align:center}@media (max-width:959px){.BottomBanner__title___nyksF{max-width:502px;font-size:20px;line-height:32px}}.BottomBanner__button___2vjL6{width:100%;margin-top:32px;color:#fff;font-weight:500;font-size:16px;line-height:24px}.BottomBanner__button___2vjL6:hover{text-decoration:none}@media (min-width:720px){.BottomBanner__button___2vjL6{width:auto}}@media (min-width:960px){.BottomBanner__button___2vjL6{margin-top:40px}}.BottomBanner__addressForm___8HlAj{max-width:546px;margin:32px auto 0}@media (min-width:720px){.BottomBanner__addressForm___8HlAj{margin-top:40px}}.BottomBanner__left_image___1qUa6,.BottomBanner__right_image___1nOhZ{position:absolute;top:0}.BottomBanner__left_image___1qUa6{left:0;display:none;width:480px;height:400px}@media (min-width:960px){.BottomBanner__left_image___1qUa6{display:block}}.BottomBanner__right_image___1nOhZ{top:auto;right:0;bottom:-80px;height:315px}@media (min-width:960px){.BottomBanner__right_image___1nOhZ{top:0;bottom:inherit;width:480px;height:auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "BottomBanner__container___19REb",
	"right_image": "BottomBanner__right_image___1nOhZ",
	"content": "BottomBanner__content___23m-K",
	"title": "BottomBanner__title___nyksF",
	"button": "BottomBanner__button___2vjL6",
	"addressForm": "BottomBanner__addressForm___8HlAj",
	"left_image": "BottomBanner__left_image___1qUa6"
};
module.exports = ___CSS_LOADER_EXPORT___;
