// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBox__isDark___2n9TV .CtaBox__emailField___2rgGg input{border-color:#2441c5;color:#fff;background-color:#1b31a4}.CtaBox__isDark___2n9TV .CtaBox__emailField___2rgGg input::-moz-placeholder{color:#fff}.CtaBox__isDark___2n9TV .CtaBox__emailField___2rgGg input:-ms-input-placeholder{color:#fff}.CtaBox__isDark___2n9TV .CtaBox__emailField___2rgGg input::placeholder{color:#fff}.CtaBox__isDark___2n9TV .CtaBox__emailField___2rgGg input:hover{border-color:#829af2}.CtaBox__isDark___2n9TV .CtaBox__emailField___2rgGg input:focus{border-color:#829af2;box-shadow:0 0 0 4px #2d50e6}.CtaBox__emailContainer___29CRw{display:flex;flex-direction:row;grid-gap:8px;gap:8px;width:100%}.CtaBox__emailContainer___29CRw .CtaBox__button___--IiS{flex-grow:1;flex-shrink:0}@media (max-width:1279px){.CtaBox__emailContainer___29CRw{flex-direction:column;max-width:300px}.CtaBox__emailContainer___29CRw .CtaBox__emailField___2rgGg{width:100%}}.CtaBox__appsContainer___XTafj{display:flex;grid-gap:25px;gap:25px}@media (max-width:719px){.CtaBox__appsContainer___XTafj{justify-content:center}}.CtaBox__appImage___p2mS5{border:1px solid #fff;border-radius:8px}@media (max-width:719px){.CtaBox__appImage___p2mS5{width:40vw;max-width:152px;height:auto;min-height:0;max-height:48px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"isDark": "CtaBox__isDark___2n9TV",
	"emailField": "CtaBox__emailField___2rgGg",
	"emailContainer": "CtaBox__emailContainer___29CRw",
	"button": "CtaBox__button___--IiS",
	"appsContainer": "CtaBox__appsContainer___XTafj",
	"appImage": "CtaBox__appImage___p2mS5"
};
module.exports = ___CSS_LOADER_EXPORT___;
