// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProcessArrowBlock__container___Q22z3{padding:128px 0 24px}.ProcessArrowBlock__inner___3jL9K{max-width:1280px;text-align:center}.ProcessArrowBlock__description___NbG0K p{color:inherit;font-weight:400;font-size:16px;line-height:24px;color:#6b6651}.ProcessArrowBlock__description___NbG0K h2,.ProcessArrowBlock__description___NbG0K h3{color:inherit;font-weight:500;font-size:40px;line-height:56px}.ProcessArrowBlock__items___1_7gl{display:flex;flex-direction:column;align-items:center;justify-content:center;margin-top:60px}@media (min-width:1280px){.ProcessArrowBlock__items___1_7gl{flex-direction:row}}.ProcessArrowBlock__cta_button___1m2RW{display:none;text-decoration:none}@media (min-width:1280px){.ProcessArrowBlock__cta_button___1m2RW{display:inline-block;margin-top:48px}}.ProcessArrowBlock__item___3zWpo{max-width:320px;min-height:230px;margin:24px;padding:24px 32px;border:1px solid #eae9e3;border-radius:8px}.ProcessArrowBlock__icon___2sXTf{margin-bottom:24px}.ProcessArrowBlock__title___2CWpC{color:inherit;font-weight:500;font-size:20px;line-height:32px;margin:0}.ProcessArrowBlock__link___1owuw{display:flex;justify-content:center;margin-top:24px}.ProcessArrowBlock__link___1owuw a{max-width:195px;text-align:left}.ProcessArrowBlock__arrow___4snPj{margin:10px;transform:rotate(90deg)}@media (min-width:1280px){.ProcessArrowBlock__arrow___4snPj{transform:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "ProcessArrowBlock__container___Q22z3",
	"inner": "ProcessArrowBlock__inner___3jL9K",
	"description": "ProcessArrowBlock__description___NbG0K",
	"items": "ProcessArrowBlock__items___1_7gl",
	"cta_button": "ProcessArrowBlock__cta_button___1m2RW",
	"item": "ProcessArrowBlock__item___3zWpo",
	"icon": "ProcessArrowBlock__icon___2sXTf",
	"title": "ProcessArrowBlock__title___2CWpC",
	"link": "ProcessArrowBlock__link___1owuw",
	"arrow": "ProcessArrowBlock__arrow___4snPj"
};
module.exports = ___CSS_LOADER_EXPORT___;
