<template>
  <section :class="$style.container">
    <Wrapper id="home-highlight" :class="$style.inner">
      <Card
        :card-object="carouselCardData.content"
        :carousel-array="carouselCardData.carouselContent"
        is-green
      />
      <Card :card-object="rightCardData" />
    </Wrapper>
  </section>
</template>

<script>
import { Card } from './components'

export default {
  name: 'HighlightSection',

  components: { Card },

  props: {
    items: {
      type: Array,
      default: null,
    },
    primary: {
      type: Object,
      default: null,
    },
  },

  computed: {
    rightCardData() {
      const primary = this.primary
      return {
        headline: primary.headline,
        title: primary.card_title,
        ctaText: primary.card_cta_text,
        ctaLink: primary.card_cta_link,
        image: primary.card_image,
      }
    },

    carouselCardData() {
      const primary = this.primary
      return {
        content: {
          headline: primary.carousel_logo,
          title: primary.carousel_title,
          ctaText: primary.carousel_cta_text,
          ctaLink: primary.carousel_cta_link,
        },
        carouselContent: this.items,
      }
    },
  },

  methods: {},
}
</script>

<style lang="scss" module>
.container {
  margin: 80px 24px;
}

.inner {
  display: block;
  display: grid;
  gap: 24px;
  margin: 0 auto;

  @include above(medium) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}
</style>
