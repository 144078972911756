// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonSlice__buttonContainer___2v2mx{padding:64px 24px;text-align:center}.ButtonSlice__inner___2yTvq{max-width:694px}.ButtonSlice__title___1sb55{color:inherit;font-weight:500;font-size:40px;line-height:56px;margin-bottom:24px}.ButtonSlice__description___gnoIa{color:inherit;font-weight:400;font-size:16px;line-height:24px;margin-bottom:24px;color:#6b6651}.ButtonSlice__description___gnoIa strong{color:#2c2302;font-weight:500}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"buttonContainer": "ButtonSlice__buttonContainer___2v2mx",
	"inner": "ButtonSlice__inner___2yTvq",
	"title": "ButtonSlice__title___1sb55",
	"description": "ButtonSlice__description___gnoIa"
};
module.exports = ___CSS_LOADER_EXPORT___;
