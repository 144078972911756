<template>
  <section :class="$style.container" data-luko-tracking="Hero">
    <Wrapper
      :class="$style.inner"
      :style="backgroundStyles(primary.background_image.url)"
    >
      <div
        :class="[
          $style.content,
          { [$style.backgroundShadow]: primary.background_shadow },
        ]"
      >
        <Title :title-params="primary.title" :class="$style.title" />
        <RichText
          :text-params="primary.description"
          :class="$style.description"
        />
        <div :class="$style.ctaSection">
          <LkButton
            v-if="primary.cta_text"
            :class="$style.cta"
            :href="getFormatedLink(primary.cta_link)"
            data-cta="calendly"
          >
            {{ primary.cta_text }}
          </LkButton>
        </div>
        <div v-if="primary.trustpilot" :class="$style.trustpilot">
          <a
            :href="getTrustpilotLink"
            target="_blank"
            rel="noopener"
            :class="$style.link"
          >
            <div :class="$style.trustpilotContent">
              <TrustpilotStars inline-small />
              <span :class="$style.trustpilotText">
                {{ primary.trustpilot_text }}
              </span>
            </div>
          </a>
        </div>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import { getTrustpilotLink } from '~/mixins/getTrustpilotLink'
import { getFormatedLink } from '~/mixins/getFormatedLink'
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import LkButton from '~/components/LkButton'
import { backgroundStyles } from '~/mixins/backgroundStyles'
import TrustpilotStars from '~/components/TrustpilotStars'

export default {
  name: 'HeroFullImage',
  components: {
    Title,
    RichText,
    LkButton,
    TrustpilotStars,
  },

  mixins: [backgroundStyles, getFormatedLink, getTrustpilotLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  min-height: 480px;

  @include below(small) {
    height: auto;
  }
}

.inner {
  max-width: 1440px;
  height: 100%;
  min-height: 480px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include below(small) {
    min-height: 480px;

    background-size: cover;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  height: 100%;
  padding: 68px 120px;

  @include below(small) {
    padding: 32px 64px;
  }
}
.backgroundShadow {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.title {
  @include typo-mega-title;
  width: 85%;

  color: white;

  @include below(small) {
    @include typo-large-title;
    width: 100%;

    color: white;
  }
}
.description {
  @include typo-headline;
  width: 85%;
  margin-top: 16px;

  color: white;

  @include below(small) {
    @include typo-body;
    width: 100%;

    color: white;
  }
}
.ctaSection {
  margin-top: 58px;
}
.cta {
  @include below(small) {
    width: 100%;
  }
}

.trustpilot {
  margin-top: 62px;
}

.trustpilotContent {
  display: flex;
  align-items: center;
}
.trustpilotText {
  margin-left: 8px;

  color: white;
}

.link {
  text-decoration: none;
}
</style>
