// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BundleProduct__container___1NOU2{margin:128px 0;padding:0 24px}.BundleProduct__header___1ftzC{display:flex;align-items:center;justify-content:space-between}@media (max-width:959px){.BundleProduct__header___1ftzC{flex-direction:column}}@media (max-width:959px){.BundleProduct__title___2VwZe{text-align:center}}.BundleProduct__list___AGkfM{display:grid;grid-template-columns:repeat(1,1fr);grid-gap:40px;gap:40px;margin-top:24px}@media (min-width:960px){.BundleProduct__list___AGkfM{grid-template-columns:repeat(2,1fr)}}.BundleProduct__item___wyJ2N{display:flex;grid-gap:15px;gap:15px}@media (max-width:539px){.BundleProduct__item___wyJ2N{flex-direction:column;text-align:center}}.BundleProduct__content___iDYee{display:flex;flex-direction:column;margin-top:8px}.BundleProduct__itemTitle___ROi0G{color:inherit;font-weight:500;font-size:20px;line-height:32px}@media (max-width:539px){.BundleProduct__itemTitle___ROi0G{margin:0 auto}}.BundleProduct__itemText___3xzkR{margin-top:8px;color:#6b6651}.BundleProduct__itemLink___3zKJY{margin-top:16px}@media (max-width:539px){.BundleProduct__itemLink___3zKJY{margin:16px auto 0}}@media (max-width:539px){.BundleProduct__imageContainer___1D8x2{display:flex;justify-content:center}}.BundleProduct__itemImage___AK1Ms{width:180px;height:180px;border-radius:8px;-o-object-fit:cover;object-fit:cover}@media (max-width:539px){.BundleProduct__itemImage___AK1Ms{width:100%;height:240px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "BundleProduct__container___1NOU2",
	"header": "BundleProduct__header___1ftzC",
	"title": "BundleProduct__title___2VwZe",
	"list": "BundleProduct__list___AGkfM",
	"item": "BundleProduct__item___wyJ2N",
	"content": "BundleProduct__content___iDYee",
	"itemTitle": "BundleProduct__itemTitle___ROi0G",
	"itemText": "BundleProduct__itemText___3xzkR",
	"itemLink": "BundleProduct__itemLink___3zKJY",
	"imageContainer": "BundleProduct__imageContainer___1D8x2",
	"itemImage": "BundleProduct__itemImage___AK1Ms"
};
module.exports = ___CSS_LOADER_EXPORT___;
