<template>
  <div>
    <component
      :is="slice.slice_type"
      v-for="(slice, sliceIndex) in slices"
      :key="sliceIndex"
      :primary="slice.primary"
      :items="slice.items"
      :slice="slice"
      muted="muted"
    />
  </div>
</template>

<script>
import GivebackSchema from '~/components/slices/GivebackSchema'
import { HighlightBlockSlice, PressList } from '~/components/slices'

import {
  Hero,
  Termination,
  HighlightSection,
  NewsletterSection,
  VideoCarrousel,
} from './components'

export default {
  name: 'HomeModule',
  components: {
    Hero,
    press_list: PressList,
    highlight_block: HighlightBlockSlice,
    termination_section: Termination,
    highlight_section: HighlightSection,
    giveback_schema: GivebackSchema,
    newsletter_section: NewsletterSection,
    video_list: VideoCarrousel,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },

  computed: {
    slices() {
      return this.data.body
    },
  },
}
</script>
