// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArticleSlice__sideBar___OZI7A{display:flex;flex-direction:column;flex-shrink:0;align-self:stretch;max-width:300px}@media (min-width:1280px){.ArticleSlice__sideBar___OZI7A{max-width:327px}}.ArticleSlice__summary___3qS0_{display:none}@media (min-width:960px){.ArticleSlice__summary___3qS0_{display:block;flex-grow:1}}.ArticleSlice__articleContainer___1N_pF{margin:80px 24px 128px}@media (max-width:959px){.ArticleSlice__articleContainer___1N_pF{margin:80px 24px 64px}}.ArticleSlice__inner___2HWpA{max-width:620px;margin:0 auto}@media (min-width:960px){.ArticleSlice__inner___2HWpA{display:flex;align-items:flex-start;justify-content:space-between;max-width:1280px}}.ArticleSlice__content___3ggqp{max-width:620px;overflow-x:hidden}.ArticleSlice__content___3ggqp a{text-decoration:none}.ArticleSlice__content___3ggqp a:hover{text-decoration:underline}.ArticleSlice__content___3ggqp>div:first-child{padding-top:0}@media (min-width:960px){.ArticleSlice__content___3ggqp{margin:0 80px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"sideBar": "ArticleSlice__sideBar___OZI7A",
	"summary": "ArticleSlice__summary___3qS0_",
	"articleContainer": "ArticleSlice__articleContainer___1N_pF",
	"inner": "ArticleSlice__inner___2HWpA",
	"content": "ArticleSlice__content___3ggqp"
};
module.exports = ___CSS_LOADER_EXPORT___;
