<template>
  <section id="calendly" ref="calendly" :class="$style.container">
    <div :class="$style.loading">
      <span>Loading Calendly</span>
      <img src="~/assets/images/loader.gif" :class="$style.loader" />
    </div>
  </section>
</template>

<script>
const openCalendly = (url, query) => () => {
  if (window.Calendly && window.innerWidth < 720) {
    // open calendly popup only mobile(sm) view
    window.Calendly.initPopupWidget({
      url,
      prefill: {
        name: query.name || '',
        email: query.email || '',
        guests: query.guests || '',
        customAnswers: {
          a1: query.a1 || '',
          a2: query.a2 || '',
          a3: query.a3 || '',
        },
      },
    })
  }
}

export default {
  name: 'EmbedCalendly',

  head() {
    return {
      script: [
        {
          hid: 'extscript',
          src: 'https://assets.calendly.com/assets/external/widget.js',
          async: true,
          callback: this.initInlineCalendly,
        },
      ],
      link: [
        {
          href: 'https://assets.calendly.com/assets/external/widget.css',
          rel: 'stylesheet',
        },
      ],
    }
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
    }
  },

  mounted() {
    if (this.primary.link && this.primary.link.url) {
      Array.from(document.querySelectorAll("[data-cta='calendly']")).forEach(
        (el) => {
          el.removeEventListener(
            'click',
            openCalendly(this.primary.link.url, this.$route.query)
          )
          el.addEventListener(
            'click',
            openCalendly(this.primary.link.url, this.$route.query)
          )
        }
      )
    }
  },

  methods: {
    initInlineCalendly() {
      if (window.Calendly) {
        window.Calendly.initInlineWidget({
          url: this.primary.link.url,
          parentElement: this.$refs.calendly,
          prefill: {
            name: this.$route.query.name || '',
            email: this.$route.query.email || '',
            guests: this.$route.query.guests || '', // Calendly widget bug, so not working
            customAnswers: {
              a1: this.$route.query.a1 || '',
              a2: this.$route.query.a2 || '',
              a3: this.$route.query.a3 || '',
            },
          },
          utm: {},
        })
      }
    },
  },
}
</script>
<style lang="scss" module>
.container {
  position: relative;

  display: flex;
  align-items: center;
  min-width: 320px;
  height: 1180px;
  padding-top: 80px;
  @media screen and (max-width: 1000px) {
    height: 1400px;
  }
  @include below(small) {
    display: none;
  }
  iframe {
    position: relative;
    z-index: 2;
  }
}
.loading {
  position: absolute;
  top: 30%;
  left: 50%;

  display: flex;
  align-items: center;

  transform: translateX(-50%);
}
.loader {
  width: 35px;
  height: 35px;
}
</style>
