// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Card__card___3mudZ{display:flex;flex-direction:column;justify-content:space-between;border-radius:8px;overflow:hidden;text-align:center;background-color:#fcf7e8}.Card__card___3mudZ.Card__cardGreen___3WWT-{background-color:#ebf5f3}.Card__card___3mudZ.Card__hasCarousel___Z-4Ip{display:block;padding:32px 24px}.Card__card___3mudZ.Card__hasCarousel___Z-4Ip .Card__cardHeading___3ThQV{padding:0}@media (min-width:720px){.Card__card___3mudZ.Card__hasCarousel___Z-4Ip{padding:80px 73px 40px}}.Card__cardHeading___3ThQV{padding:32px 24px}@media (min-width:720px){.Card__cardHeading___3ThQV{padding:80px 73px 40px}}.Card__headline___35ZJk{color:inherit;font-weight:400;font-size:14px;line-height:20px;color:#6b6651}.Card__logoHeadline___161Rh{height:20px}.Card__title___2Arll{margin-top:16px}.Card__link___3fscu{margin:32px auto 0}.Card__image___CbKH3,.Card__link___3fscu{display:block}.Card__image___CbKH3{justify-self:flex-end;width:100%}.Card__carousel___1Uc8S{margin-top:32px;padding-top:32px;border-top:1px solid #d5e9e6;color:#115146}@media (min-width:720px){.Card__carousel___1Uc8S{margin-top:40px;padding-top:40px}}.Card__carouselHeader___1iiMB{display:flex;grid-gap:32px;gap:32px;align-items:center;justify-content:center}.Card__arrowButton___3_07G{padding:0;border:0;background-color:transparent;cursor:pointer}.Card__profilePicture___ltLuv{width:48px;height:48px;border-radius:24px}.Card__carouselBody___KE_DD,.Card__infos___QZCSz{margin-top:24px}.Card__infos___QZCSz{color:inherit;font-weight:400;font-size:14px;line-height:20px}.Card__infos___QZCSz strong{color:#002922}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"card": "Card__card___3mudZ",
	"cardGreen": "Card__cardGreen___3WWT-",
	"hasCarousel": "Card__hasCarousel___Z-4Ip",
	"cardHeading": "Card__cardHeading___3ThQV",
	"headline": "Card__headline___35ZJk",
	"logoHeadline": "Card__logoHeadline___161Rh",
	"title": "Card__title___2Arll",
	"link": "Card__link___3fscu",
	"image": "Card__image___CbKH3",
	"carousel": "Card__carousel___1Uc8S",
	"carouselHeader": "Card__carouselHeader___1iiMB",
	"arrowButton": "Card__arrowButton___3_07G",
	"profilePicture": "Card__profilePicture___ltLuv",
	"carouselBody": "Card__carouselBody___KE_DD",
	"infos": "Card__infos___QZCSz"
};
module.exports = ___CSS_LOADER_EXPORT___;
